import SSO from "@code-skills-community/sso-react-client";
import { Checkbox, CircularProgress, Paper, Switch, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { AiOutlineWarning } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { codeSkillsApi } from "../../utils/axios_config";
import { initialValue } from "../edit/Edit";
import "./contest.css";


const calDuration = (end, start) => {
  let delta = Math.abs(new Date(end) - new Date(start)) / 1000;
  // calculate (and subtract) whole days
  let days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  let hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  let minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  return (
    days.toString() +
    " Days " +
    hours.toString() +
    " hours " +
    minutes.toString() +
    " minutes "
  );
};

export const Contest = () => {
  const { id } = useParams();
  // notistack
  const { enqueueSnackbar } = useSnackbar();

  const { getAuthToken } = React.useContext(SSO.Context);
  const [values, setValues] = React.useState(initialValue);
  const [loading, setLoading] = React.useState(true);
  const [showLeaderboard, setShowLeaderboard] = React.useState(true);
  const [questionsListed, setQuestionsListed] = React.useState(false);

  const handleQuestionsListed = () => {
    if (window.confirm("Are you sure you want to make the questions available in LEARN section? Once made public, these questions will not be listed in contest!")) {


      var switchValue = !questionsListed;
      setQuestionsListed(switchValue);
      getAuthToken(true).then((token) => {
        codeSkillsApi
          .get(`contest/${id}/makeQuestionsListed`, {
            headers: {
              Authorization: token,
            },
          })
          .then((data) => {
            // console.log(data);
            if (data.data.error) {
              enqueueSnackbar(data.data.error.message, { variant: "error" });

            }
            else {
              enqueueSnackbar("Questions are now public", { variant: "success" });

            }
          })
          .catch((err) => {
            console.log("Error:", err);
            enqueueSnackbar(err, { variant: "error" });
          });
      });
    }
  };


  React.useEffect(() => {
    getAuthToken(true).then((token) => {
      codeSkillsApi
        .get(`contest/${id}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((data) => {
          // console.log(data);
          setLoading(false);
          const {
            name,
            prizepool,
            description,
            prizes,
            rules,
            sponsors,
            tags,
            start,
            end,
            leaderboardVisible,
            _id,
            status,
            isOpen,
            allowedEmails
          } = data.data.contest;

          const sponsorsValue = sponsors.map((obj) => {
            const { name, image, link } = obj;
            return { name, image, link };
          });

          const prizesValue = prizes.map((obj) => {
            const { title, description, amount } = obj;
            return { title, description, amount };
          });

          setValues({
            id: _id,
            name: name,
            prizepool: prizepool,
            description: description,
            prizes: prizesValue,
            rules: rules,
            sponsors: sponsorsValue,
            tags: tags,
            leaderboardVisible: leaderboardVisible,
            start: new Date(Date.parse(start)).toString().split(" "),
            end: new Date(Date.parse(end)).toString().split(" "),
            status: status,
            duration: calDuration(end, start),
            isOpen: isOpen,
            allowedEmails
          });
          setShowLeaderboard(leaderboardVisible);
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(error, { variant: "error" });
          console.log("Error:", error);
        });
    });
  }, []);

  const handleLeaderboard = () => {
    var switchValue = !showLeaderboard;
    setShowLeaderboard(switchValue);
    getAuthToken(true).then((token) => {
      codeSkillsApi
        .get(`contest/${id}/leaderBoardVisibility?value=${switchValue}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((data) => {
          // console.log(data);
        })
        .catch((err) => {
          console.log("Error:", err);
          enqueueSnackbar(err, { variant: "error" });
        });
    });
  };




  return loading ? (
    <div className="loader">
      <CircularProgress />
    </div>
  ) : (
    <>
      <ReactTooltip />


      <div className="createBody">

        <div className="createContainer contestInfo">
          <div
            className="imageContainer"
            style={{
              background: `url('${process.env.REACT_APP_imageAPI_BASE}/contest-images/${id}.jpg')`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div
              className="nameContainer status"
              style={{
                background:
                  values.status === "active"
                    ? "rgba(17, 186, 17, 0.7)"
                    : "rgba(186, 17, 17, 0.7)",
                textTransform: "uppercase",
              }}
            >
              {values.status}
            </div>
          </div>
          <div className="contestInfoContainer subContainers">
            <div className="colContainer">
              <div className="titleContainer title">
                {values.name}

              </div>
              <div className="nameContainer">
                <span className="titleContainer">Show Leaderboard : &nbsp;</span>
                <span className="valueContainer">
                  {" "}
                  No
                  <Switch
                    checked={showLeaderboard}
                    onChange={handleLeaderboard}
                  ></Switch>
                  Yes
                </span>
              </div>


              <div className="nameContainer">
                <span className="titleContainer">Total Prize : &nbsp;</span>
                <span className="valueContainer">
                  ₹
                  {values.prizepool
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
              </div>
              <div className="nameContainer">
                <span className="titleContainer">Starts at : &nbsp;</span>
                <span className="valueContainer">
                  {values.start.slice(1, 4).join(" ")} , {values.start[4]}
                </span>
              </div>
              <div className="nameContainer">
                <span className="titleContainer">Ends at : &nbsp;</span>
                <span className="valueContainer">
                  {values.end.slice(1, 4).join(" ")} , {values.end[4]}
                </span>
              </div>
              <div className="nameContainer">
                <span className="titleContainer">Duration : &nbsp;</span>
                <span className="valueContainer">{values.duration}</span>
              </div>
              <div className="nameContainer">
                <span className="titleContainer">
                  LeaderBoard Visible : &nbsp;
                </span>
                <span className="valueContainer">
                  {showLeaderboard ? "True" : "False"}
                </span>
              </div>
              <div className="nameContainer">
                <span className="titleContainer">
                  Restricted Contest : &nbsp;
                </span>
                <span className="valueContainer">
                  {!values.isOpen ? "True" : "False"}
                </span>
              </div>
              <div className="flex items-start justify-start flex-wrap flex-col mb-8">
                <p className="">Allowed Users:</p>
                {values.isOpen ? <p className="flex items-start justify-start flex-wrap w-full " >Open Contest</p> : <div className="flex items-start justify-start flex-wrap w-full p-2 pl-0">
                  {values?.allowedEmails?.length > 0 && values?.allowedEmails.map((email, idx) => (
                    <Paper key={idx} variant="outlined" sx={{ backgroundColor: "#F7F8FA", width: "max-content" }} className="m-0.5 rounded-md">
                      <div className="flex items-center justify-center p-1 w-max ">
                        <p className="px-2">
                          {email}
                        </p>

                      </div>
                    </Paper>
                  ))}
                </div>}
              </div>
              <div className="">
                <div className="titleContainer">Description : &nbsp;</div>
                <div className="valueContainer desc">{values.description}</div>
              </div>
            </div>
            <div className="colContainer rightCol">
              <Link className="m-5" to={`/questions/${values.id}`}>
                <Button variant="contained" className=" m-5">
                  QUESTIONS
                </Button>
              </Link>
              <Link className="m-5" to={`/questions/create/${values.id}`}>
              <Button variant="contained" className=" m-5">
                 ADD QUESTIONS
                </Button>
              </Link>
              <Link className="m-5" to={`/announcements/${values.id}`}>
              <Button variant="contained" className=" m-5">
              ANNOUNCEMENTS
                </Button>
                
              </Link>

            </div>

          </div>
          <div className="contestRules subContainers">
            <div className="titleContainer rulesTitle">Rules :</div>
            <div className="valueContainer ruleContainer">
              <ul>
                {values.rules.map((rule, idx) => {
                  return <li key={idx} className="subRule">{rule}</li>;
                })}
              </ul>
            </div>
          </div>
          <div className="contestPrize subContainers">
            <div className="titleContainer prizeTitle">Prizes :</div>
            <div className="prizeContainer">
              {values.prizes.map((prizes, idx) => {
                return (
                  <div key={idx} className="subPrizes">
                    <div className="inlineContainer">
                      <div className="nameContainer">
                        <span className="titleContainer">Title :&nbsp;</span>
                        <span className="valueContainer">{prizes.title}</span>
                      </div>
                      <div className="nameContainer">
                        <span className="titleContainer">
                          Prize Amount :&nbsp;
                        </span>
                        <span className="valueContainer">
                          ₹
                          {prizes.amount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </span>
                      </div>
                    </div>
                    <div className="prizeDesc nameContainer">
                      <div className="titleContainer">Description :&nbsp;</div>
                      <div className="valueContainer">{prizes.description}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="contestPrize subContainers">
            <div className="titleContainer prizeTitle">Sponsors :</div>
            <div className="prizeContainer">
              {values.sponsors.map((sponsor, idx) => {
                return (
                  <div key={idx} className="subPrizes">
                    <div className="inlineContainer">
                      <div className="nameContainer">
                        <span className="titleContainer">Name :&nbsp;</span>
                        <span className="valueContainer">{sponsor.name}</span>
                      </div>
                      <div className="nameContainer">
                        <span className="titleContainer">Image Link :&nbsp;</span>
                        <span className="valueContainer">
                          <a href={sponsor.image}>Click Here</a>
                        </span>
                      </div>
                      <div className="nameContainer">
                        <span className="titleContainer">Link :&nbsp;</span>
                        <span className="valueContainer">
                          <a href={sponsor.link}>Click Here</a>
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="contestTags subContainers">
            <div className="titleContainer TagsTitle">Tags :</div>
            <div className="TagsContainer">
              {values.tags.map((tag, idx) => {
                return <div key={idx} className="singleTag">{tag}</div>;
              })}
            </div>
          </div>
          <div className="subContainers">
            <AiOutlineWarning className="warningIcon" data-tip="Once the questions are made publicly available, it cannot be undone" /> Make Questions Listed
            <Checkbox value={questionsListed} onChange={handleQuestionsListed}></Checkbox>
          </div>
        </div>
      </div>
    </>
  );
};
