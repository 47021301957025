import { getLevelData, User } from '@code-skills-community/react-user'
import React from 'react';
import { Backdrop, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell'
import { Box } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import SSO from '@code-skills-community/sso-react-client';
import TableContainer from "@mui/material/TableContainer";
import { CodeSkillProfileApi } from '../../utils/axios_config';
import { useSnackbar } from 'notistack';
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import { GrNext } from 'react-icons/gr'

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// sorting
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'rank',
    numeric: true,
    disablePadding: false,
    label: 'Rank',
    align: "left"
  },
  {
    id: 'level',
    numeric: true,
    disablePadding: false,
    label: 'Level',
    align: "left"
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
    align: "center"
  },
  {
    id: 'contact number',
    numeric: false,
    disablePadding: false,
    label: 'Phone',
    align: "center"
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    align: "center"
  },
  {
    id: 'score',
    numeric: true,
    disablePadding: false,
    label: 'Score',
    align: "center"
  },
  {
    id: 'username',
    numeric: false,
    disablePadding: false,
    label: 'User handel',
    align: "right"
  },

];

export function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, idx) => {
          return (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  );
}


export function EnhancedTable() {
  const { getAuthToken, isLoggedIn } = React.useContext(SSO.Context)
  const [page, setPage] = React.useState(1)
  const [loading, setLoading] = React.useState(true)
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('rank');
  const [rows, setRows] = React.useState([]);
  // notistack
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    setLoading(true)
    getAuthToken(true).then(token => {
      CodeSkillProfileApi
        .get(`/?page=${page}`, {
          headers: {
            Authorization: token
          }
        })
        .then(res => {
          setLoading(false);
          setRows(res.data.users)
        })
        .catch(error => {
          enqueueSnackbar(error.message, { variant: 'error' })
          console.log("Error:", error.message)
        })
    });
  }, [page]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Paper
      className="w-[80%] p-4 m-auto rounded-md mt-4 mb-4"
      style={{
        backgroundColor: 'rgba(255,255,255, 0.9)',
        color: 'white !important',
      }}
    >
      <TableContainer>
        <Table
          sx={{
            minWidth: 100,
            color: "black",
            border: 0,
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
            },
          }}
          aria-labelledby="tableTitle"
          size={'small'}
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length}
          />
          {loading && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            />
          )}
          <TableBody sx={{ width: '100%' }}>
            {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
              const userData = getLevelData(row.level);
              const labelId = `enhanced-table-checkbox-${index}`
              return (
                <TableRow
                  key={row._id}
                  sx={{
                    backgroundColor:
                      index % 2 === 0 ? 'rgba(0,0,0,0.08)' : '',
                    transition: 'all 500ms ease',
                    cursor: 'pointer',
                    [`&:hover`]: {
                      backgroundColor: 'rgba(0,0,0,0.05)',
                    },
                  }}
                >
                  <TableCell
                    id={labelId}
                    align="left"
                    scope="row"
                    sx={{
                      color: 'black',
                      textOverflow: 'ellipsis',
                      cursor: 'pointer',
                      borderTopLeftRadius: '6px',
                      borderBottomLeftRadius: '6px',
                      width: '10%'
                    }}
                  >
                    <p className="font-bold">{row.rank}</p>
                  </TableCell>
                  <TableCell
                    id={labelId}
                    align="center"
                    scope="row"
                    sx={{
                      color: 'black',
                      textOverflow: 'ellipsis',
                      cursor: 'pointer',
                    }}
                  >
                    <div className="flex flex-col items-start justify-start">
                      <img src={userData.img} className="w-7 h-max" alt={userData.name} />
                      <p className='font-light'>{userData.name}</p>
                    </div>
                  </TableCell>
                  <TableCell
                    id={labelId}
                    align="center"
                    scope="row"
                    sx={{
                      color: 'black',
                      width: "20%"
                    }}
                  >
                    <User userId={row._id} username={row.username} />
                  </TableCell>
                  <TableCell
                    id={labelId}
                    align="center"
                    scope="row"
                    sx={{
                      color: 'black',
                      textOverflow: 'ellipsis',
                      cursor: 'pointer',
                      width: "10%"
                    }}
                  >
                    {row?.email}
                  </TableCell>
                  <TableCell
                    id={labelId}
                    align="center"
                    scope="row"
                    sx={{
                      color: 'black',
                      textOverflow: 'ellipsis',
                      cursor: 'pointer',
                      width: "10%"
                    }}
                  >
                    {row?.phone}
                  </TableCell>

                  <TableCell
                    id={labelId}
                    align="center"
                    scope="row"
                    sx={{
                      color: 'black',
                      textOverflow: 'ellipsis',
                      cursor: 'pointer',
                      width: "20%"
                    }}
                  >
                    {row.score}
                  </TableCell>
                  <TableCell
                    id={labelId}
                    align="right"
                    scope="row"
                    sx={{
                      color: 'black',
                      textOverflow: 'ellipsis',
                      cursor: 'pointer',
                      width: "10%"
                    }}
                  >
                    <p>@{row.username}</p>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="w-full flex justify-end items-center p-2">
        <div className="w-max flex p-2 items-center justify-center">
          {page > 1 && <div className='h-[35px] w-[35px] flex items-center justify-center text-lg p-2 m-1 shadow-[0px_0px_5px_rgba(0,0,0,0.15)] cursor-pointer' onClick={() => setPage(page => page - 1)}>
            <MdOutlineArrowBackIosNew />
          </div>}
          <div className='h-[35px] w-[35px] flex items-center justify-center text-lg p-2 m-1 shadow-[0px_0px_5px_rgba(0,0,0,0.15)]'>{page}</div>
          {rows.length % 25 === 0 && (
            <div className='h-[35px] w-[35px] flex items-center justify-center text-lg p-2 m-1 shadow-[0px_0px_5px_rgba(0,0,0,0.15)] cursor-pointer' onClick={() => setPage(page => page + 1)}><GrNext /></div>
          )}
        </div>
      </div>
    </Paper>
  )
}