import React from 'react';
import { useSnackbar } from 'notistack'
import { Backdrop, CircularProgress } from '@mui/material'
import { SolutionComponent } from '../../components/solutionComponent/solutionComponent'
import { useParams } from 'react-router-dom';
import { codeSkillsApi } from '../../utils/axios_config';
import SSO from '@code-skills-community/sso-react-client';

export const Solution = () => {
    const { id } = useParams();
    const { getAuthToken } = React.useContext(SSO.Context);
    const { enqueueSnackbar } = useSnackbar();
    const [solution, setSolution] = React.useState();
    const [question, setQuestion] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const [tLoader, setTLoader] = React.useState(false);

    React.useEffect(() => {
        getAuthToken(true).then(token => {
            codeSkillsApi
                .get(`solutions/${id}`, {
                    headers: {
                        Authorization: token
                    }
                })
                .then(res => {
                    console.log(res.data.solution)
                    setSolution(res.data.solution)
                })
                .catch(error => {
                    enqueueSnackbar(error.message, { variant: 'error' })
                    console.log("Error:", error.message)
                })

        });
    }, [])

    React.useEffect(() => {
        if (solution?.questionId) {
            getAuthToken(true).then(token => {
                codeSkillsApi
                    .get(`question/${solution.questionId}`, {
                        headers: {
                            Authorization: token
                        }
                    })
                    .then(res => {
                        setQuestion(res.data.data);
                        setLoading(false);
                    })
                    .catch(error => {
                        setLoading(false)
                        enqueueSnackbar(error, { variant: 'error' });
                        console.log("Error:", error)
                    })
            })
        }
        else if (solution) {
            setQuestion({
                name: "Compile Quesiton",
                difficulty: "complie",
                type: 'CP'
            });
            setLoading(false);
        }
        if (solution?.status !== 'Queued') {
            setTLoader(false)
        }
    }, [solution])

    return (
        <>
            <div className="max-h-screen overflow-x-hidden ">
                <div className="p-[2px] min-h-screen w-full">
                    {loading ? (
                        <Backdrop
                            sx={{
                                color: '#fff',
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                            }}
                            open={loading}
                        >
                            <div className="flex items-center justify-center w-full m-auto min-h-screen">
                                <CircularProgress color={'inherit'} size={30} />
                            </div>
                        </Backdrop>
                    ) :
                        (
                            <div className="flex items-start justify-evenly flex-col pt-5 lg:w-[70%] w-[95%] px-2 m-auto">
                                <SolutionComponent
                                    currentSolution={solution}
                                    question={question}
                                    tLoader={tLoader}
                                />
                            </div>
                        )}
                </div>
            </div>
        </>
    )
}
