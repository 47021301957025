import { CircularProgress, Paper, Switch, TextareaAutosize, TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import React from 'react';
import { useForm } from '../../utils/useForm';
import './create.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { VscDiffRemoved } from 'react-icons/vsc'
import SSO from '@code-skills-community/sso-react-client';
import { useContext } from 'react';
import { codeSkillsApi } from '../../utils/axios_config';
import { FileUpload } from '../../components/fileUpload/FileUpload';
import { useSnackbar } from 'notistack';
import { MdClose } from 'react-icons/md';
import { Textarea } from '@material-tailwind/react';

export const Createcontest = () => {
    console.log("called")
    const { getAuthToken } = useContext(SSO.Context)

    // form values
    const [value, codeError, handleChange, handlePrizeObj, handleRemove, addInput] = useForm({
        name: "",
        code: "",
        prizepool: 0,
        description: "",
        // leaderboardVisible: true,
        prizes: [
            {
                amount: 0,
                title: "",
                description: "",
            },
        ],
        rules: [""],
        sponsors: [{
            name: "",
            image: "",
            link: ""
        }],
        tags: [""]
    })
    const [startDate, setStart] = React.useState(dayjs(new Date()))
    const [endDate, setEnd] = React.useState(dayjs(new Date()))
    const [contestId, setContestId] = React.useState("");
    const [restrict, setRestrict] = React.useState(true);
    const [isListed, setIsListed] = React.useState(true);
    const [emails, setEmails] = React.useState([])
    const [emailValue, setEmailValue] = React.useState("")
    const [error, setError] = React.useState(null);

    // notistack
    const { enqueueSnackbar } = useSnackbar();

    //after submit submit loading
    const [subLoad, setSubLoad] = React.useState(false);

    // for file upload
    const [isFile, setFileOpen] = React.useState(false);
    const handleFileClose = () => setFileOpen((old) => !old)

    const handleEndDateChange = (newValue) => {
        setEnd(newValue);
    };

    const handleStartDateChange = (newValue) => {
        setStart(newValue);
    };

    const checkRequired = () => {
        let keys = Object.keys(value);
        const errorKeys = keys.filter((obj) => {
            return (obj === 'leaderboardVisible' || obj === 'prizepool') ? false : value[obj].length !== 0 ? false : true;
        })
        value['prizepool'] <= 0 && errorKeys.push('prizepool')
        if (errorKeys.length !== 0) {
            enqueueSnackbar("Please fill " + errorKeys.join(' , ').toUpperCase() + " fields", { variant: 'error' });
            return false;
        }
        const trueArrPrizes = value.sponsors.map((obj) => {
            return obj.name.length === 0 || obj.image.length === 0 || obj.link.length === 0 ? false : true;
        })
        if (trueArrPrizes.includes(false)) {
            enqueueSnackbar("Please fill Sponsors fields properly", { variant: 'error' });
            return false;
        }
        const trueArrSponsors = value.prizes.map((obj) => {
            return obj.amount === 0 || obj.title.length === 0 || obj.description.length === 0 ? false : true;
        })
        if (trueArrSponsors.includes(false)) {
            enqueueSnackbar("Please fill Prizes fields properly", { variant: 'error' });
            return false;
        }
        return true;
    }

    const handleSubmit = () => {
        setSubLoad(true);
        if (checkRequired()) {
            let body = {
                ...value, start: startDate, end: endDate, isListed
            }
            if (restrict && handlePermission()) {
                body.isOpen = false
                body.allowedEmails = emails
            } else {
                body.isOpen = true
                body.allowedEmails = []
            }
            getAuthToken(true).then(token => {
                console.log(token)
                codeSkillsApi
                    .post(`contest/add`, body,
                        {
                            headers: {
                                Authorization: token
                            }
                        })
                    .then(res => {
                        setSubLoad(false);
                        if (!res.data.err) {
                            setContestId(res.data.data._id);
                            enqueueSnackbar("Successfully Created", { variant: 'success' })
                            return setFileOpen(true)
                        }
                        throw new Error({ message: 'Something went wrong' });
                    })
                    .catch(error => {
                        enqueueSnackbar(error.response.data.error.details.body[0].message || error.message, { variant: 'error' });
                        setSubLoad(false);
                        console.log("Error:", error)
                    })
            });

        }

    }

    const handleContestRestriction = async () => {
        var switchValue = !restrict;
        setRestrict(switchValue);

    };
    const handleContestListing = async () => {
        var switchValue = !isListed;
        setIsListed(switchValue);

    };


    const handleEmailChange = evt => {
        setEmailValue(evt.target.value)
        setError(null)
    };

    const handleKeyDown = (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            let value = emailValue.trim();
            if (value && isValid(value)) {
                setEmails([...emails, value])
                setEmailValue("")
            }
        }
    };

    const handleDelete = item => {
        let emailList = emails.filter(e => e !== item)
        setEmails([...emailList])
    };



    const isValid = (email) => {
        let error = null;

        if (isInList(email)) {
            error = `${email} has already been added.`;
        }

        if (!isEmail(email)) {
            error = `${email} is not a valid email address.`;
        }

        if (error) {
            setError(error)

            return false;
        }

        return true;
    }

    const isInList = (email) => {
        return emails.includes(email);
    }

    const isEmail = (email) => {
        let res = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
        return res
    }

    const handlePermission = () => {
        console.log({ emails })
        if (emails.length === 0) {
            setError("Please enter atleast one email")
            return false
        }

        return true
    }

    // return (
    //     <div className="font-['Raleway'] w-full min-h-[100vh] flex flex-col bg-white">
    //         <div className='m-4 mx-8'>
    //             <div className='font-["Montserrat"] text-[1.5rem] font-[700] opacity-[0.75] tracking-wide border-b-2 pb-5'>Contest Information</div>
    //             <div className='flex items-start mb-5 py-5 border-b-2 gap-[25vw]'>
    //                 <div>
    //                     <div className='font-["Montserrat"] font-[700] text-[1.3rem] opacity-[0.75] tracking-wider'>
    //                         Basic Information
    //                     </div>
    //                     <div className='font-[500] text-[0.9rem] opacity-[0.75] tracking-wider'>
    //                         Fill basic information about contest.
    //                     </div>
    //                 </div>
    //                 <div className='flex flex-col justify-between align-center gap-4'>
    //                     <TextField
    //                         id='outlined-multiline-flexible'
    //                         required

    //                         type="text"
    //                         name="name"
    //                         value={value.name}
    //                         onChange={handleChange}
    //                         label="Contest Name"
    //                         className='form-input'
    //                     />
    //                     <TextField
    //                         id='outlined-multiline-flexible'
    //                         required
    //                         minLength={4}
    //                         type="text"
    //                         name="code"
    //                         value={value.code}
    //                         onChange={(event) => handleChange(event)}
    //                         label="Contest Code"
    //                         className='form-input'
    //                     />
    //                     <TextField
    //                         id='outlined-multiline-flexible'
    //                         type="number"
    //                         name="prizepool"
    //                         value={value.prizepool === 0 ? '' : value.prizepool}
    //                         onChange={handleChange}
    //                         label="Total Prize Pool"
    //                         className='form-input'
    //                     />
    //                     <TextareaAutosize
    //                         minRows={3}
    //                         name="description"
    //                         value={value.description}
    //                         onChange={handleChange}
    //                         placeholder="Contest Description"
    //                         className='form-input'
    //                     />
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // )

    return (
        <div className="createBody">
            <FileUpload isFile={isFile} handleFileClose={handleFileClose} id={contestId} />
            <div className="createContainer">
                <div className="formDates">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label="Start Date"
                            value={startDate}
                            onChange={handleStartDateChange}
                            renderInput={(params) => <TextField {...params} />}
                            className="datePicker"
                        />
                        <DateTimePicker
                            label="End Date"
                            value={endDate}
                            onChange={handleEndDateChange}
                            renderInput={(params) => <TextField {...params} />}
                            className="datePicker"
                        />
                    </LocalizationProvider>
                </div>
                <div className="blockContainer">
                    <div className="perInput">
                        <div className="inputTitle">
                            Contest Name
                        </div>
                        <input
                            required
                            type="text"
                            name="name"
                            value={value.name}
                            onChange={handleChange}
                            placeholder="Name"
                            style={{ width: "30vw" }}
                        />
                    </div>
                    <div className="codeContainer">
                        <div className="errorText">{codeError}</div>
                        <div className="perInput">
                            <div className="inputTitle">
                                Code
                            </div>
                            <input
                                required
                                minLength={4}
                                type="text"
                                name="code"
                                value={value.code}
                                onChange={(event) => handleChange(event)}
                                placeholder="code"
                            />
                        </div>
                    </div>

                    <div className="perInput">
                        <div className="inputTitle">
                            Total Prize
                        </div>
                        <input
                            type="number"
                            name="prizepool"
                            value={value.prizepool}
                            onChange={handleChange}
                            placeholder="prize"
                        />
                    </div>

                </div>
                <div className="perInput formDescription">
                    <div className="inputTitle descTitle">
                        Description
                    </div>
                    <textarea
                        name="description"
                        value={value.description}
                        onChange={handleChange}
                        placeholder="Describe the Contest..."
                    />
                </div>
                {/* prizes */}
                <div className="formPrize perInput">
                    <div className="inputTitle">
                        Prizes
                    </div>
                    <div className="subInputContainer">
                        {value.prizes.map((obj, idx) => {
                            return (
                                <div className='subPrizeContainer'>
                                    <div className='perSubInput'>
                                        <div className="blockContainer">
                                            <div className="perInput">
                                                <div className="inputTitle">
                                                    Prize Title
                                                </div>
                                                <input
                                                    type="text"
                                                    name="title"
                                                    style={{ width: "20vw" }}
                                                    value={obj.title}
                                                    id={`prizes ${idx}`}
                                                    onChange={handlePrizeObj}
                                                />
                                            </div>
                                            <div className="perInput">
                                                <div className="inputTitle">
                                                    Prize Amount
                                                </div>
                                                <input
                                                    type="number"
                                                    step="any"
                                                    name="amount"
                                                    value={obj.amount}
                                                    id={`prizes ${idx}`}
                                                    onChange={handlePrizeObj}
                                                />
                                            </div>
                                        </div>
                                        <div className="perInput formDescription">
                                            <div className="inputTitle">
                                                Prize Description
                                            </div>
                                            <textarea
                                                name="description"
                                                value={obj.description}
                                                id={`prizes ${idx}`}
                                                onChange={handlePrizeObj}
                                            />
                                        </div>
                                    </div>
                                    <VscDiffRemoved onClick={() => handleRemove("prizes", idx)} style={{ fontSize: "2em", margin: "12px", cursor: "pointer", color: "var(--color-accent)" }} />
                                </div>
                            )
                        })}
                    </div>
                    <div className='addBtn' onClick={() => { addInput("prizes") }}>Add</div>
                </div>
                {/* prizes */}
                {/* sponsors */}
                <div className="formPrize perInput">
                    <div className="inputTitle">
                        Sponsors
                    </div>
                    <div className="subInputContainer">
                        {value.sponsors.map((obj, idx) => {
                            return (
                                <div className='subPrizeContainer'>
                                    <div className='perSubInput'>
                                        <div className="blockContainer">
                                            <div className="perInput">
                                                <div className="inputTitle">
                                                    Name
                                                </div>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    style={{ width: "20vw" }}
                                                    value={obj.name}
                                                    id={`sponsors ${idx}`}
                                                    onChange={handlePrizeObj}
                                                />
                                            </div>
                                            <div className="perInput">
                                                <div className="inputTitle">
                                                    Image
                                                </div>
                                                <input
                                                    type="text"
                                                    name="image"
                                                    value={obj.image}
                                                    id={`sponsors ${idx}`}
                                                    onChange={handlePrizeObj}
                                                />
                                            </div>
                                            <div className="perInput">
                                                <div className="inputTitle">
                                                    Link
                                                </div>
                                                <input
                                                    name="link"
                                                    value={obj.link}
                                                    id={`sponsors ${idx}`}
                                                    onChange={handlePrizeObj}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <VscDiffRemoved onClick={() => handleRemove("sponsors", idx)} style={{ fontSize: "2em", margin: "12px", cursor: "pointer", color: "var(--color-accent)" }} />
                                </div>
                            )
                        })}
                    </div>
                    <div className='addBtn' onClick={() => { addInput("sponsors") }}>Add</div>
                </div>
                {/* sponsors */}
                <div className="rules perInput">
                    <div className="inputTitle">
                        Rules
                    </div>
                    <div className="subPrizeContainer rulesContainer">
                        {value.rules.map((data, idx) => {
                            return (
                                <div className="singleInput">
                                    <input
                                        type="text"
                                        name="rules"
                                        value={data}
                                        id={`rules ${idx}`}
                                        onChange={handleChange}
                                        placeholder="rules"
                                        style={{ width: "85%" }}
                                    />
                                    <VscDiffRemoved onClick={() => handleRemove("rules", idx)} style={{ fontSize: "2em", margin: "12px", cursor: "pointer", color: "var(--color-accent)" }} />
                                </div>
                            )
                        })}
                    </div>
                    <div className='addBtn' onClick={() => { addInput("rules") }}>Add</div>
                </div>
                <div className="tags perInput">
                    <div className="inputTitle">
                        tags
                    </div>
                    <div className="subPrizeContainer tagContainer">
                        {value.tags.map((data, idx) => {
                            return (
                                <div className="singleInput">
                                    <input
                                        type="text"
                                        name="tags"
                                        value={data}
                                        id={`tags ${idx}`}
                                        onChange={handleChange}
                                        placeholder="tags"
                                        style={{ width: "90%" }}
                                    />
                                    <VscDiffRemoved onClick={() => handleRemove("tags", idx)} style={{ fontSize: "2em", margin: "12px", cursor: "pointer", color: "var(--color-accent)" }} />
                                </div>
                            )
                        })}
                    </div>
                    <div className='addBtn' onClick={() => { addInput("tags") }}>Add</div>
                </div>
                <div className="nameContainer">
                    <span className="titleContainer">List is contest : &nbsp;</span>
                    <span className="valueContainer">
                        {" "}
                        No
                        <Switch
                            checked={isListed}
                            onChange={handleContestListing}
                        ></Switch>
                        Yes
                    </span>
                </div>

                <div className="nameContainer">
                    <span className="titleContainer">Restrict Contest : &nbsp;</span>
                    <span className="valueContainer">
                        {" "}
                        No
                        <Switch
                            checked={restrict}
                            onChange={handleContestRestriction}
                        ></Switch>
                        Yes
                    </span>
                </div>

                {
                    restrict &&
                    <Paper className="p-4 relative">
                        <div className="flex items-center justify-start flex-wrap p-2">
                            {emails.length > 0 && emails.map((email, idx) => (
                                <Paper key={idx} variant="outlined" sx={{ backgroundColor: "#F7F8FA", width: "max-content" }} className="m-0.5 rounded-md">
                                    <div className="flex items-center justify-center p-1 w-max ">
                                        <p className="px-2">
                                            {email}
                                        </p>
                                        <div onClick={(e) => handleDelete(email)} className="cursor-pointer px-2 rounded-full">
                                            <MdClose />
                                        </div>
                                    </div>
                                </Paper>
                            ))}
                        </div>
                        <input
                            className={`${error && " has-error"} px-4 py-2 w-max rounded-md border border-black border-opacity-60 focus:outline-none `}
                            value={emailValue}
                            placeholder="Type email addresses and press `Enter`..."
                            onKeyDown={handleKeyDown}
                            onChange={handleEmailChange}
                        />
                        {error && <p className="error my-4">{error}</p>}


                    </Paper>

                }
                <div className="submitBtn">
                    {
                        subLoad ?
                            <div className="subBtn">
                                <CircularProgress style={{ width: '0.9em', height: "0.9em" }} />
                            </div> :
                            <div className="subBtn" onClick={() => handleSubmit()}>
                                <span>Submit</span>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}
