import React from 'react';

const Settings = React.createContext();

export const useSettings = () => {
    return React.useContext(Settings);
}

export const SnackbarContext = ({ children }) => {
    const [fetchError, setFetchError] = React.useState(""); //msg
    const [isError, setError] = React.useState(false); // open close snackbar
    const triggerError = () => setError((isError) => !isError); //trigger error
    const [forError, setForError] = React.useState(false);

    return (
        <Settings.Provider value={{
            fetch: [fetchError, setFetchError],
            error: [isError, setError],
            triggerError,
            forAlert: [forError, setForError]
        }}>
            {children}
        </Settings.Provider>
    )
}

