import { Button, CircularProgress, FormControlLabel, Switch, TextareaAutosize, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { PopUp } from './PopUp';

export const TestFields = ({ value, handleChange, handleSubmit, submitLoad, type }) => {

    const [isFile, setFilePop] = React.useState(false);
    const handleFileClose = () => setFilePop(old => !old);
    const [forFile, setForFile] = React.useState('');

    return (
        <>
            <PopUp handleChange={handleChange} forFile={forFile} isFile={isFile} handleFileClose={handleFileClose} type={type} />
            <div className='fieldsContainer'>
                <div className="codeNameContainer">
                    <span className="codeName">Name</span>
                    <input
                        type="text"
                        name="name"
                        value={value.name}
                        onChange={handleChange}
                        className="codeNameText" />
                </div>
                <div className="inline">
                    <div className="codeNameContainer">
                        <span className="codeName">Score</span>
                        <input
                            type="number"
                            name="score"
                            style={{ width: "18vw" }}
                            value={value.score}
                            onChange={handleChange}
                            className="codeNameText inlineText" />
                    </div>
                    {type === "CP" && <div className="codeNameContainer">
                        <span className="codeName">Time Limit</span>
                        <input
                            type="number"
                            name="time"
                            style={{ width: "18vw" }}
                            value={value.time}
                            onChange={handleChange}
                            className="codeNameText inlineText" />
                    </div>}
                    <div className="codeNameContainer">
                        {type === "CP" && <FormControlLabel control={<Switch name="outputNo" checked={value.outputNo} onChange={handleChange} />} label="Output Number" />}
                        <FormControlLabel control={<Switch name="required" checked={value.required} onChange={handleChange} />} label="Required" />
                    </div>
                </div>
                <div className="inline">
                    <Button onClick={() => {
                        setForFile('input')
                        return setFilePop(true)
                    }}>
                        Upload Input File
                    </Button>
                    {<Button onClick={() => {
                        setForFile('output')
                        return setFilePop(true)
                    }}>
                        Upload Output File
                    </Button>}
                </div>
                <div className="submitBtn">

                    {submitLoad ?
                        <div className="subBtn">
                            <CircularProgress style={{ width: '0.9em', height: "0.9em" }} />
                        </div> :
                        <div className="subBtn" onClick={() => handleSubmit()}>
                            <span>Submit</span>
                        </div>}

                </div>
            </div>
        </>
    )
}
