import React from 'react'
import { codeSkillsApi } from './axios_config'
import SSO from '@code-skills-community/sso-react-client';
import axios from 'axios';

export const useForm = (initialValues) => {
    const { getAuthToken } = React.useContext(SSO.Context);
    const [values, setValues] = React.useState(initialValues)
    const [codeError, setCodeError] = React.useState("")

    const addInput = (name) => {
        setValues((prev) => {
            const defaultValue = name === "prizes" ? {
                amount: 0,
                title: "",
                description: "",
            } : name === "sponsors" ? {
                name: "",
                image: "",
                link: ""
            } : "";
            return {
                ...prev,
                [name]: [...prev[name], defaultValue]
            }
        })
    }

    const checkError = (value) => {
        setCodeError("")
        if (value.length <= 3)
            setCodeError("Min Length allowed is 3")
        else {
            getAuthToken(true).then(token => {
                codeSkillsApi
                    .get(`contest/available/${value}`,
                        {
                            headers: {
                                Authorization: token
                            },
                        },

                    )
                    .then(data => data.data.data ? setCodeError("") : setCodeError("Code already exists"))
                    .catch(error => {
                        // setError(true)
                        // setFetchError(error)
                        console.log("Error:", error)
                        setCodeError(error.message)
                    })

            });
        }
    }

    const handleRemove = (name, idx) => {
        setValues((prev) => {
            return {
                ...prev,
                [name]: prev[name].filter((value, i) => {
                    return i !== idx
                })
            }
        })
    }

    const handlePrizeObj = (event) => {
        const { name, value, id } = event.target
        const pname = id.split(" ")[0];
        setValues((prev) => {
            return {
                ...prev,
                [pname]: prev[pname].map((obj, idx) => {
                    return idx.toString() === id.split(" ")[1] ? { ...obj, [name]: value } : obj
                })
            }
        })
    }

    const handleChange = (event) => {
        //destruct the event.target object
        const { name, value, type, checked, id } = event.target
        name === 'code' && checkError(value)
        const dynamicArrays = ["tags", "rules"]
        setValues(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : dynamicArrays.includes(name) ?
                    [...prevFormData[name].map((pre, idx) => idx.toString() === id.split(" ")[1] ? value : pre)]
                    : value
            }
        })
    }
    return [values, codeError, handleChange, handlePrizeObj, handleRemove, addInput, setValues]
}
