import React, { useContext, useState, useEffect, useCallback, useRef } from 'react'
import SSO from '@code-skills-community/sso-react-client';
import { codeSkillsApi } from '../../utils/axios_config';
import { Homecard } from '../../components/homecard/Homecard';
import './home_style.css'
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';


export const Home = () => {
    const { getAuthToken } = useContext(SSO.Context);
    // contains the list of contest
    const [contests, setContests] = useState([]);
    // axios fetch error
    const [loading, setLoading] = useState(true);
    // notistack
    const { enqueueSnackbar } = useSnackbar();

    // for infinite scrolling
    const [page, setPage] = useState(1);
    const [hasMore, setMore] = useState(false);
    const observer = useRef();
    const lastElementRef = useCallback(node => {
        console.log("inside callback");
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                console.log("visible");
                setPage(prev => prev + 1);
            }
        })
        if (node) observer.current.observe(node);
    }, [loading, hasMore])
    // for infinite scrolling

    useEffect(() => {
        setLoading(true)
        getAuthToken(true).then(token => {
            codeSkillsApi
                .get(`contest/list?page=${page}`, {
                    headers: {
                        Authorization: token
                    }
                })
                .then(res => {
                    setLoading(false);
                    console.log(res.data.data)
                    setContests(prev => [...prev, ...res.data.data])
                    setMore(res.data.data.length > 0 ? true : false)
                })
                .catch(error => {
                    setMore(false);
                    enqueueSnackbar(error.message, { variant: 'error' })
                    console.log("Error:", error.message)
                })
        });
    }, [page]);

    return (
        <div className="homeContainer">
            <div className="homeTitle">Available Contests</div>
            <div className="homeCardsContainer">
                {contests.map((obj) => {
                    return <span key={obj._id}><Homecard data={obj} /></span>
                })}
            </div>
            <div className="hello" ref={lastElementRef}></div>
            {loading && <div className='loader'> <CircularProgress /> </div>}
        </div>
    )
}
