import React from 'react'
import SSO from '@code-skills-community/sso-react-client'
import { codeSkillsApi } from '../../utils/axios_config';
import { useParams } from 'react-router-dom';
import { Checkbox, CircularProgress, Paper, Switch, TextField } from '@mui/material';
import { useForm } from '../../utils/useForm';
import { VscDiffRemoved } from 'react-icons/vsc';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FileUpload } from '../../components/fileUpload/FileUpload';
import { useSnackbar } from 'notistack';
import { MdClose } from 'react-icons/md';

export const initialValue = {
    prizepool: 0,
    description: "",
    prizes: [
        {
            amount: 0,
            title: "",
            description: "",
        },
    ],
    rules: [""],
    sponsors: [{
        name: "",
        image: "",
        link: ""
    }],
    tags: [""],
    leaderboardVisible: false,
}

export const Edit = () => {
    const { getAuthToken } = React.useContext(SSO.Context);

    // notistack
    const { enqueueSnackbar } = useSnackbar();

    const { id } = useParams();
    const [loading, setLoading] = React.useState(true);

    // storing initial values to compare
    const [init, setInit] = React.useState(initialValue);

    // submit btn
    const [submitLoad, setSubmitLoad] = React.useState(false);

    const [value, codeError, handleChange, handlePrizeObj, handleRemove, addInput, setValues] = useForm(initialValue);

    // dates stored and updated ones
    const [start, setStart] = React.useState("");
    const [end, setEnd] = React.useState("");
    const [initStart, setInitStart] = React.useState("");
    const [initEnd, setInitEnd] = React.useState("");
    // for file upload
    const [isFile, setFileOpen] = React.useState(false);
    const [contId, setContId] = React.useState("");
    const handleFileClose = () => setFileOpen((old) => !old)
    const [restrict, setRestrict] = React.useState(true);
    const [emails, setEmails] = React.useState([])
    const [emailValue, setEmailValue] = React.useState("")
    const [error, setError] = React.useState(null);
    const [isListed, setIsListed] = React.useState(true);



    React.useEffect(() => {
        getAuthToken(true).then(token => {
            codeSkillsApi
                .get(`contest/${id}`, {
                    headers: {
                        Authorization: token
                    }
                })
                .then(data => {
                    setLoading(false);
                    const { name, prizepool, description, prizes, rules, sponsors, tags, start, end, leaderboardVisible, _id, isOpen, allowedEmails, isListed } = data.data.contest;

                    const sponsorsValue = sponsors.map((obj) => {
                        const { name, image, link } = obj
                        return { name, image, link }
                    })

                    const prizesValue = prizes.map((obj) => {
                        const { title, description, amount } = obj
                        return { title, description, amount }
                    })

                    setInit({
                        name: name,
                        prizepool: prizepool,
                        description: description,
                        prizes: prizesValue,
                        rules: rules,
                        sponsors: sponsorsValue,
                        tags: tags,
                        leaderboardVisible: leaderboardVisible,
                    });

                    setContId(_id);

                    setValues({
                        name: name,
                        prizepool: prizepool,
                        description: description,
                        prizes: prizesValue,
                        rules: rules,
                        sponsors: sponsorsValue,
                        tags: tags,
                        leaderboardVisible: leaderboardVisible,
                    });

                    setInitStart(start);
                    setInitEnd(end);
                    setStart(start);
                    setEnd(end);
                    setEmails([...allowedEmails])
                    setRestrict(!isOpen)
                    setIsListed(isListed)
                })
                .catch(error => {
                    setLoading(false)
                    enqueueSnackbar(error, { variant: 'error' })
                    console.log("Error:", error)
                })
        });
    }, [])

    const handleContestListing = async () => {
        var switchValue = !isListed;
        setIsListed(switchValue);

    };

    // comaping value sto pass in api
    const compareValues = () => {
        let keys = Object.keys(init);
        const changed_arr = keys.filter((name) => {
            return init[name] !== value[name] ? true : false;
        })
        const submissionValue = {}
        if (initStart !== start)
            submissionValue['start'] = start
        if (initEnd !== end)
            submissionValue['end'] = end;

        changed_arr.forEach((name) => {
            submissionValue[name] = value[name];
        })
        return submissionValue;
    }

    const handleEndDateChange = (newValue) => {
        setEnd(newValue);
    };

    const handleStartDateChange = (newValue) => {
        setStart(newValue);
    };

    const checkRequired = () => {
        let keys = Object.keys(value);
        const errorKeys = keys.filter((obj) => {
            return (obj === 'leaderboardVisible' || obj === 'prizepool') ? false : value[obj].length !== 0 ? false : true;
        })
        value['prizepool'] <= 0 && errorKeys.push('prizepool')
        if (errorKeys.length !== 0) {
            enqueueSnackbar("Please fill " + errorKeys.join(' , ').toUpperCase() + " fields", { variant: 'error' });
            return false;
        }
        const trueArrPrizes = value.prizes.map((obj) => {
            return obj.amount === 0 || obj.title.length === 0 || obj.description.length === 0 ? false : true;
        })
        if (trueArrPrizes.includes(false)) {
            enqueueSnackbar("Please fill Prizes fields properly", { variant: 'error' });
            return false;
        }
        const trueArrSponsors = value.prizes.map((obj) => {
            return obj.amount === 0 || obj.title.length === 0 || obj.description.length === 0 ? false : true;
        })
        if (trueArrSponsors.includes(false)) {
            enqueueSnackbar("Please fill Sponsors fields properly", { variant: 'error' });
            return false;
        }
        return true;
    }

    const handleSubmit = () => {
        setSubmitLoad(true);
        if (checkRequired()) {
            let body = compareValues()
            body.isListed = isListed
            if (restrict && handlePermission()) {
                body.isOpen = false
                body.allowedEmails = emails
            } else {
                body.isOpen = true
                body.allowedEmails = []
            }
            getAuthToken(true).then(token => {
                console.log(token)
                codeSkillsApi
                    .put(`contest/${id}`, body,
                        {
                            headers: {
                                Authorization: token
                            }
                        })
                    .then(data => {
                        if (!data.data.err) {
                            setSubmitLoad(false);
                            enqueueSnackbar('Created !', { variant: 'success' })
                            return setFileOpen(true)
                        }
                        throw new Error("Something went wrong")
                    })
                    .catch(error => {
                        setSubmitLoad(false);
                        enqueueSnackbar(error.message, { variant: 'error' });
                        console.log("Error:", error)
                    })
            });
        }

    }

    const handleContestRestriction = async () => {
        var switchValue = !restrict;
        setRestrict(switchValue);

    };



    const handleEmailChange = evt => {
        setEmailValue(evt.target.value)
        setError(null)
    };

    const handleKeyDown = (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            let value = emailValue.trim();
            if (value && isValid(value)) {
                setEmails([...emails, value])
                setEmailValue("")
            }
        }
    };

    const handleDelete = item => {
        let emailList = emails.filter(e => e !== item)
        setEmails([...emailList])
    };



    const isValid = (email) => {
        let error = null;

        if (isInList(email)) {
            error = `${email} has already been added.`;
        }

        if (!isEmail(email)) {
            error = `${email} is not a valid email address.`;
        }

        if (error) {
            setError(error)

            return false;
        }

        return true;
    }

    const isInList = (email) => {
        return emails.includes(email);
    }

    const isEmail = (email) => {
        let res = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
        return res
    }

    const handlePermission = () => {
        console.log({ emails })
        if (emails.length === 0) {
            setError("Please enter atleast one email")
            return false
        }

        return true
    }

    return (
        loading ? <div className="loader"><CircularProgress /></div> :
            <div className="createBody">
                <FileUpload isFile={isFile} handleFileClose={handleFileClose} id={contId} />
                <div className="createContainer">
                    <div className="formDates">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                label="Start Date"
                                value={start}
                                onChange={handleStartDateChange}
                                renderInput={(params) => <TextField {...params} />}
                                className="datePicker"
                            />
                            <DateTimePicker
                                label="End Date"
                                value={end}
                                onChange={handleEndDateChange}
                                renderInput={(params) => <TextField {...params} />}
                                className="datePicker"
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="blockContainer">
                        <div className="perInput">
                            <div className="inputTitle">
                                Contest Name
                            </div>
                            <input
                                required
                                type="text"
                                name="name"
                                value={value.name}
                                onChange={handleChange}
                                placeholder="Name"
                                style={{ width: "30vw" }}
                            />
                        </div>
                        <div className="perInput">
                            <div className="inputTitle">
                                Total Prize
                            </div>
                            <input
                                type="number"
                                name="prizepool"
                                value={value.prizepool}
                                onChange={handleChange}
                                placeholder="prize"
                            />
                        </div>
                        <div className="checkboxLabelContainer" style={{ display: "flex", alignItems: "center", height: "5vh" }}>
                            <Checkbox
                                name="leaderboardVisible"
                                checked={value.leaderboardVisible}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                                label="LeaderBoard"
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                            />
                            <span style={{ fontFamily: "Montserrat" }}>LeaderBoard Visibility</span>
                        </div>
                    </div>
                    <div className="perInput formDescription">
                        <div className="inputTitle descTitle">
                            Description
                        </div>
                        <textarea
                            name="description"
                            value={value.description}
                            onChange={handleChange}
                            placeholder="Describe the Contest..."
                        />
                    </div>
                    {/* prizes */}
                    <div className="formPrize perInput">
                        <div className="inputTitle">
                            Prizes
                        </div>
                        <div className="subInputContainer">
                            {value.prizes.map((obj, idx) => {
                                return (
                                    <div className='subPrizeContainer'>
                                        <div className='perSubInput'>
                                            <div className="blockContainer">
                                                <div className="perInput">
                                                    <div className="inputTitle">
                                                        Prize Title
                                                    </div>
                                                    <input
                                                        type="text"
                                                        name="title"
                                                        style={{ width: "20vw" }}
                                                        value={obj.title}
                                                        id={`prizes ${idx}`}
                                                        onChange={handlePrizeObj}
                                                    />
                                                </div>
                                                <div className="perInput">
                                                    <div className="inputTitle">
                                                        Prize Amount
                                                    </div>
                                                    <input
                                                        type="number"
                                                        step="any"
                                                        name="amount"
                                                        value={obj.amount}
                                                        id={`prizes ${idx}`}
                                                        onChange={handlePrizeObj}
                                                    />
                                                </div>
                                            </div>
                                            <div className="perInput formDescription">
                                                <div className="inputTitle">
                                                    Prize Description
                                                </div>
                                                <textarea
                                                    name="description"
                                                    value={obj.description}
                                                    id={`prizes ${idx}`}
                                                    onChange={handlePrizeObj}
                                                />
                                            </div>
                                        </div>
                                        <VscDiffRemoved onClick={() => handleRemove("prizes", idx)} style={{ fontSize: "2em", margin: "12px", cursor: "pointer", color: "var(--color-accent)" }} />
                                    </div>
                                )
                            })}
                        </div>
                        <div className='addBtn' onClick={() => { addInput("prizes") }}>Add</div>
                    </div>
                    {/* prizes */}
                    {/* sponsors */}
                    <div className="formPrize perInput">
                        <div className="inputTitle">
                            Sponsors
                        </div>
                        <div className="subInputContainer">
                            {value.sponsors.map((obj, idx) => {
                                return (
                                    <div className='subPrizeContainer'>
                                        <div className='perSubInput'>
                                            <div className="blockContainer">
                                                <div className="perInput">
                                                    <div className="inputTitle">
                                                        Name
                                                    </div>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        style={{ width: "20vw" }}
                                                        value={obj.name}
                                                        id={`sponsors ${idx}`}
                                                        onChange={handlePrizeObj}
                                                    />
                                                </div>
                                                <div className="perInput">
                                                    <div className="inputTitle">
                                                        Image
                                                    </div>
                                                    <input
                                                        type="text"
                                                        name="image"
                                                        value={obj.image}
                                                        id={`sponsors ${idx}`}
                                                        onChange={handlePrizeObj}
                                                    />
                                                </div>
                                                <div className="perInput">
                                                    <div className="inputTitle">
                                                        Link
                                                    </div>
                                                    <input
                                                        name="link"
                                                        value={obj.link}
                                                        id={`sponsors ${idx}`}
                                                        onChange={handlePrizeObj}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <VscDiffRemoved onClick={() => handleRemove("sponsors", idx)} style={{ fontSize: "2em", margin: "12px", cursor: "pointer", color: "var(--color-accent)" }} />
                                    </div>
                                )
                            })}
                        </div>
                        <div className='addBtn' onClick={() => { addInput("sponsors") }}>Add</div>
                    </div>
                    {/* sponsors */}
                    <div className="rules perInput">
                        <div className="inputTitle">
                            Rules
                        </div>
                        <div className="subPrizeContainer rulesContainer">
                            {value.rules.map((data, idx) => {
                                return (
                                    <div className="singleInput">
                                        <input
                                            type="text"
                                            name="rules"
                                            value={data}
                                            id={`rules ${idx}`}
                                            onChange={handleChange}
                                            placeholder="rules"
                                            style={{ width: "85%" }}
                                        />
                                        <VscDiffRemoved onClick={() => handleRemove("rules", idx)} style={{ fontSize: "2em", margin: "12px", cursor: "pointer", color: "var(--color-accent)" }} />
                                    </div>
                                )
                            })}
                        </div>
                        <div className='addBtn' onClick={() => { addInput("rules") }}>Add</div>
                    </div>
                    <div className="tags perInput">
                        <div className="inputTitle">
                            tags
                        </div>
                        <div className="subPrizeContainer tagContainer">
                            {value.tags.map((data, idx) => {
                                return (
                                    <div className="singleInput">
                                        <input
                                            type="text"
                                            name="tags"
                                            value={data}
                                            id={`tags ${idx}`}
                                            onChange={handleChange}
                                            placeholder="tags"
                                            style={{ width: "90%" }}
                                        />
                                        <VscDiffRemoved onClick={() => handleRemove("tags", idx)} style={{ fontSize: "2em", margin: "12px", cursor: "pointer", color: "var(--color-accent)" }} />
                                    </div>
                                )
                            })}
                        </div>
                        <div className='addBtn' onClick={() => { addInput("tags") }}>Add</div>
                    </div>

                    <div className="nameContainer">
                        <span className="titleContainer">List is contest : &nbsp;</span>
                        <span className="valueContainer">
                            {" "}
                            No
                            <Switch
                                checked={isListed}
                                onChange={handleContestListing}
                            ></Switch>
                            Yes
                        </span>
                    </div>

                    <div className="nameContainer">
                        <span className="titleContainer">Restrict Contest: &nbsp;</span>
                        <span className="valueContainer">
                            {" "}
                            No
                            <Switch
                                checked={restrict}
                                onChange={handleContestRestriction}
                            ></Switch>
                            Yes
                        </span>
                    </div>

                    {
                        restrict &&
                        <Paper className="p-4 relative">
                            <div className="flex items-center justify-start flex-wrap p-2">
                                {emails.length > 0 && emails.map((email, idx) => (
                                    <Paper key={idx} variant="outlined" sx={{ backgroundColor: "#F7F8FA", width: "max-content" }} className="m-0.5 rounded-md">
                                        <div className="flex items-center justify-center p-1 w-max ">
                                            <p className="px-2">
                                                {email}
                                            </p>
                                            <div onClick={(e) => handleDelete(email)} className="cursor-pointer px-2 rounded-full">
                                                <MdClose />
                                            </div>
                                        </div>
                                    </Paper>
                                ))}
                            </div>
                            <input
                                className={`${error && " has-error"} px-4 py-2 w-max rounded-md border border-black border-opacity-60 focus:outline-none `}
                                value={emailValue}
                                placeholder="Type email addresses and press `Enter`..."
                                onKeyDown={handleKeyDown}
                                onChange={handleEmailChange}
                            />
                            {error && <p className="error my-4">{error}</p>}


                        </Paper>

                    }
                    <div className="submitBtn">

                        {submitLoad ?
                            <div className="subBtn">
                                <CircularProgress style={{ width: "0.9em", height: "0.9em" }} />
                            </div> :
                            <div className="subBtn" onClick={() => handleSubmit()}>
                                <span>Submit</span>
                            </div>}

                    </div>
                </div>
            </div>
    )
}