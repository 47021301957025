import React, { useContext } from 'react'
import './nav.css'
import SSO from '@code-skills-community/sso-react-client';
import { AiOutlinePlusSquare, AiOutlineUser } from 'react-icons/ai'
import { Link } from 'react-router-dom';
import { BsQuestionSquare } from 'react-icons/bs';
import { VscServerProcess } from 'react-icons/vsc';

export const Navbar = () => {

    // return (
    //     <div className='w-[13vw] p-5 text-white h-[100vh] z-10 fixed flex flex-col justify-between items-center bg-[#0f1527] shadow-xl'>
    //         <Link to="/">
    //             <div className='flex items-center justify-between'>
    //                 <img src="./assets/newLogo.png" alt="" className='h-[4vh] mr-1' />
    //                 <div className="text-center ml-1">
    //                     <div>CodeSkills</div>
    //                     <div className='text-[0.5em] opacity-60'>Together we grow</div>
    //                 </div>
    //             </div>
    //         </Link>
    //         <div className="flex flex-col gap-8 items-start h-[80vh]">
    //             <div className='flex items-center justify-between'>
    //                 <Link to="/users" style={{ display: "flex", alignItems: "center" }}>
    //                     <AiOutlineUser className='navLinkSym' />
    //                     <span>Users</span>
    //                 </Link>
    //             </div>
    //             <Link to="/submissions" style={{ display: "flex", alignItems: "center" }}>
    //                 <VscServerProcess className='navLinkSym' />
    //                 Submissions
    //             </Link>
    //             <Link to="/create" style={{ display: "flex", alignItems: "center" }}>
    //                 <AiOutlinePlusSquare className='navLinkSym' />
    //                 Create Contest
    //             </Link>
    //             <Link to="/questions/create/listQuestion" style={{ display: "flex", alignItems: "center" }}>
    //                 <AiOutlinePlusSquare className='navLinkSym' />
    //                 Create List Question
    //             </Link>
    //             <Link to="/questions/listedQuestions" style={{ display: "flex", alignItems: "center" }}>
    //                 <BsQuestionSquare className='navLinkSym' style={{ fontSize: '0.95em' }} />
    //                 Listed Questions
    //             </Link>
    //         </div>
    //     </div>
    // )

    return (
        <>
            <div className="navBar">
                <Link to="/">
                    <div className='navCompany'>
                        <img src="./assets/newLogo.png" alt="" className='navLogo' />
                        <div className="navName">
                            <div>CodeSkills</div>
                            <div className='navMotto'>Together we grow</div>
                        </div>
                    </div>
                </Link>
                <div className="navLinks">
                    <div className="createContest navSingleLink">
                        <Link to="/users" style={{ display: "flex", alignItems: "center" }}>
                            <AiOutlineUser className='navLinkSym' />
                            Users
                        </Link>
                        <Link to="/submissions" style={{ display: "flex", alignItems: "center" }}>
                            <VscServerProcess className='navLinkSym' />
                            Submissions
                        </Link>
                        <Link to="/create" style={{ display: "flex", alignItems: "center" }}>
                            <AiOutlinePlusSquare className='navLinkSym' />
                            Create Contest
                        </Link>
                        <Link to="/questions/create/listQuestion" style={{ display: "flex", alignItems: "center" }}>
                            <AiOutlinePlusSquare className='navLinkSym' />
                            Create List Question
                        </Link>
                        <Link to="/questions/listedQuestions" style={{ display: "flex", alignItems: "center" }}>
                            <BsQuestionSquare className='navLinkSym' style={{ fontSize: '0.95em' }} />
                            Listed Questions
                        </Link>
                    </div>
                </div>
                <SSO.LogoutButton style={{ color: "white" }} />
            </div>
        </>
    )
}
