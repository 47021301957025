import './createQ.css'
import React from 'react'
import { Fields } from '../../components/QuestionCreate/Fields';
import { useNavigate, useParams } from 'react-router-dom';
import { codeSkillsApi } from '../../utils/axios_config';
import SSO from '@code-skills-community/sso-react-client';
import { useSnackbar } from 'notistack';

export const Createquestion = () => {
    const { id } = useParams();
    const url = id === 'listQuestion' ? 'question/addListed' : 'question/add'
    const { getAuthToken } = React.useContext(SSO.Context);
    const navigate = useNavigate();

    // notistack
    const { enqueueSnackbar } = useSnackbar();

    const [value, setValue] = React.useState({
        name: "",
        description: "",
        type: "CP",
        input: "",
        output: "",
        constraints: "",
        score: 0,
        difficulty: "EASY",
        example: [
            {
                input: "",
                output: "",
                explanation: "",
            }
        ]
    });

    const addInput = (name) => {
        setValue((prev) => {
            const defaultValue = {
                input: "",
                output: "",
                explanation: "",
            }
            return {
                ...prev,
                [name]: [...prev[name], defaultValue]
            }
        })
    }
    const handleRemove = (name, idx) => {
        setValue((prev) => {
            return {
                ...prev,
                [name]: prev[name].filter((value, i) => {
                    return i !== idx
                })
            }
        })
    }
    const handlePrizeObj = (event) => {
        const { name, value, id } = event.target
        const pname = id.split(" ")[0];
        setValue((prev) => {
            return {
                ...prev,
                [pname]: prev[pname].map((obj, idx) => {
                    return idx.toString() === id.split(" ")[1] ? { ...obj, [name]: value } : obj
                })
            }
        })
    }

    // submit loading
    const [subLoad, setSubLoad] = React.useState(false);
    const triggerSub = () => setSubLoad(old => !old);

    function handleChange(event) {

        //destruct the event.target object
        const { name, value, type, checked } = event.target
        //checked and unchecked for check box

        setValue(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
    }


    const checkRequired = () => {
        let keys = Object.keys(value);
        const errorKeys = keys.filter((obj) => {
            return (obj === 'score') ? false : value[obj].length !== 0 ? false : true;
        })
        value['score'] <= 0 && errorKeys.push('score')
        if (errorKeys.length !== 0) {
            enqueueSnackbar("Please fill " + errorKeys.join(' , ').toUpperCase() + " fields", { variant: 'error' });
            setSubLoad(false)
            return false;
        }
        const trueArrPrizes = value.example.map((obj) => {
            return obj.input.length === 0 || obj.output.length === 0 || obj.explanation.length === 0 ? false : true;
        })
        if (trueArrPrizes.includes(false)) {
            enqueueSnackbar("Please fill Prizes fields properly", { variant: 'error' });
            setSubLoad(false)

            return false;
        }
        return true;
    }

    const handleSubmit = () => {
        // console.log({ value })
        triggerSub();
        checkRequired() &&
            getAuthToken(true).then(token => {
                codeSkillsApi
                    .post(url, id === 'listQuestion' ? value : { ...value, contestId: id },
                        {
                            headers: {
                                Authorization: token
                            }
                        })
                    .then(data => {
                        if (!data.data.err) {
                            triggerSub();
                            enqueueSnackbar("Successfully Created", { variant: 'success' })
                            return navigate(-1)
                        }
                        throw new Error({ message: 'Something went wrong' });
                    })
                    .catch(error => {
                        setSubLoad(false)
                        enqueueSnackbar(error.response.data.error.details.body[0].message || error.message, { variant: 'error' });
                        console.log("Error:", error)
                    })
            });
    }

    const handleExampleChange = (event, idx) => {
        // const {name, value} = event.target;

        const example = value.example.slice()
        example[idx][event.target.name] = event.target.value
        setValue(prevFormData => ({
            ...prevFormData,
            example
        }))

    }

    return (

        <div className="createBody">
            <Fields value={value} handleChange={handleChange} handleSubmit={handleSubmit} submitLoad={subLoad} addInput={addInput} handleRemove={handleRemove} handlePrizeObj={handlePrizeObj} handleExampleChange={handleExampleChange} />
        </div>

    )
}
