import { User } from '@code-skills-community/react-user'
import { Tooltip } from '@material-tailwind/react'
import { Paper, Skeleton } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useFullScreenHandle } from 'react-full-screen'
import { IoMdOpen } from 'react-icons/io'
import {
    MdCopyAll,
    MdDownload,
    MdFullscreen,
    MdRefresh,
    MdSettings,
} from 'react-icons/md'
import { Link } from 'react-router-dom'
import {
    capitalizeFirstLetter,
    copyToClipBoard,
    handleFiletoText,
} from '../../utils/helper'
import ACEeditor from '../Compiler/Ace';

export const SolutionComponent = ({
    currentSolution,
    question,
    tLoader,
}) => {
    const handleFullScreen = useFullScreenHandle()
    const [theme, setTheme] = useState('monokai')
    const [fontSize, setFontSize] = useState(14)
    const [tabSize, setTabSize] = useState(4)
    const [copied, setCopied] = useState(false)
    const [editorCode, setEditorCode] = useState('')

    useEffect(() => {
        if (currentSolution) {
            handleFiletoText(currentSolution?.file, setEditorCode)
        }
    }, [currentSolution])

    const handleEditorChange = (value) => { }

    const handleCopyToClipboard = () => {
        copyToClipBoard(editorCode)
            .then(() => {
                setCopied(true)

                setTimeout(() => {
                    setCopied(false)
                }, 3000)
            })
            .catch((err) => console.log({ err }))
    }

    const handleCodeDownload = () => {
        window.open(currentSolution?.file, '_blank')
    }

    return (
        <>
            <p className="lg:text-3xl md:text-xl font-semibold text-lg text-primary-bgColorDark mt-4 mb-2">
                Solution Details
            </p>
            <Paper
                variant="outlined"
                className="operateCardShadow p-4 m-auto rounded-md min-h-[20vh] w-full my-2 flex items-start justify-between flex-wrap"
            >
                <div>
                    <p className="my-2 text-xl text-primary-bgColorDark">
                        {question?.name}
                    </p>
                    {question._id && <Link to={`/questions/view/${question._id}`}>
                        <a>
                            <div className="my-2 flex items-center cursor-pointer hover:underline transition-all">
                                <p className="mr-2">Go to Problem</p>
                                <IoMdOpen />
                            </div>
                        </a>
                    </Link>}
                    <div className="my-2 flex items-center">
                        <p className="mr-2">Submitted By: </p>
                        <User userId={currentSolution.userId} />
                    </div>
                    <div className="my-2 flex items-center">
                        <p className="mr-2">Submitted: </p>
                        <span>{moment(currentSolution.createdAt).fromNow()}</span>
                    </div>
                </div>
                <div>
                    <div
                        className={`my-2 flex items-center lg:justify-end justify-start text-right`}
                    >
                        <p className="mr-2">Difficulty: </p>{' '}
                        <p
                            className={`${question.difficulty === 'EASY'
                                ? 'text-[#0BB6A3] font-semibold'
                                : question.difficulty === 'MEDIUM'
                                    ? 'text-[#FFBB1D] font-semibold'
                                    : question.difficulty === 'HARD'
                                        ? 'text-accent font-semibold'
                                        : 'text-[#FF3340] font-semibold'
                                }`}
                        >
                            {capitalizeFirstLetter(question.difficulty.toLowerCase())}
                        </p>
                    </div>
                    <div
                        className={`my-2 flex items-center lg:justify-end justify-start text-right`}
                    >
                        <p className="mr-2">Result:</p>
                        {currentSolution.result === 'NG' ? (
                            <MdRefresh className="rotating" color="black" size={20} />
                        ) : (
                            <p
                                className={`${currentSolution.result === 'AC'
                                    ? 'text-green-700'
                                    : currentSolution.result === 'PA' ||
                                        currentSolution.result === 'TLE'
                                        ? 'text-accent'
                                        : 'text-[#FF3340]'
                                    }   font-bold cursor-pointer`}
                            >
                                {currentSolution.result}
                            </p>
                        )}
                    </div>
                    <div
                        className={`my-2 flex items-center lg:justify-end justify-start text-right`}
                    >
                        <p className="mr-2">Status:</p>
                        <p className={`text-gray-500`}>{currentSolution.status}</p>
                    </div>
                    <div
                        className={`my-2 flex items-center lg:justify-end justify-start text-right`}
                    >
                        <p className="mr-2">Testcases: </p>
                        {tLoader ? (
                            <Skeleton
                                variant="text"
                                sx={{ fontSize: '1rem', width: '100%' }}
                            />
                        ) : (
                            <p>
                                {currentSolution?.testCases?.filter(
                                    (t) => t.result === 'AC',
                                ).length +
                                    ' / ' +
                                    currentSolution.testCases.length}{' '}
                                passed
                            </p>
                        )}
                    </div>
                </div>
            </Paper>
            {question.type === 'CP' ? (
                <Paper
                    variant="outlined"
                    className="p-4 m-auto rounded-md min-h-[60vh] h-[90vh] w-full my-2"
                    style={{
                        backgroundColor: 'white',
                        color: 'white !important',
                    }}
                >
                    <div
                        className={`h-12 bg-white border p-2 overflow-hidden relative rounded-t-md `}
                    >
                        <div className="flex items-center justify-between h-full my-auto w-full z-50">
                            <div className="px-4 py-3">
                                <p className="font-semibold lg:text-base md:text-base text-sm">
                                    <span className="hidden lg:inline-block">Language: </span>{' '}
                                    <span>
                                        {capitalizeFirstLetter(currentSolution.lang.toLowerCase())}
                                    </span>
                                </p>
                            </div>
                            <div className="flex items-center justify-start opacity-70">
                                <Tooltip
                                    content="Download code"
                                    placement="bottom"
                                    className="text-[0.6rem] rounded-sm bg-primary-bgColorDark"
                                >
                                    <div
                                        className="cursor-pointer mx-2"
                                        onClick={() => handleCodeDownload()}
                                    >
                                        <MdDownload size={20} />
                                    </div>
                                </Tooltip>
                                <Tooltip
                                    content={`${copied ? 'Copied!' : 'Click to copy on clipboard'
                                        }`}
                                    placement="bottom"
                                    className={`text-[0.6rem] rounded-sm ${copied
                                        ? 'bg-green-600 text-white'
                                        : 'bg-primary-bgColorDark'
                                        }`}
                                >
                                    <div
                                        className="cursor-pointer mx-2"
                                        onClick={() => handleCopyToClipboard()}
                                    >
                                        <MdCopyAll size={20} />
                                    </div>
                                </Tooltip>
                                <Tooltip
                                    content="Open in fullscreen"
                                    placement="bottom"
                                    className="text-[0.6rem] rounded-sm bg-primary-bgColorDark"
                                >
                                    <div
                                        className="cursor-pointer mx-2"
                                        onClick={() => {
                                            // console.log({ handleFullScreen })
                                            handleFullScreen.enter()
                                        }}
                                    >
                                        <MdFullscreen size={20} />
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`h-[calc(100%-48px)] editorFont rounded-md transition-all duration-[300ms] delay-75 ease-in-out`}
                    >
                        <ACEeditor
                            editorContent={editorCode}
                            handleEditorChange={handleEditorChange}
                            language={currentSolution?.lang.toLowerCase()}
                            handleFullScreen={handleFullScreen}
                            theme={theme}
                            fontSize={fontSize}
                            tabSize={tabSize}
                            readOnly={true}
                        />
                    </div>
                </Paper>
            ) : (
                <Paper
                    variant="outlined"
                    className="p-4 m-auto rounded-md w-full my-2"
                    style={{
                        backgroundColor: 'white',
                        color: 'white !important',
                    }}
                >
                    <div className="flex items-center justify-between h-full my-auto w-full z-50">
                        <div className="py-3">
                            <p className="font-semibold lg:text-base md:text-base text-sm">
                                <span className="hidden lg:inline-block">Language: </span>{' '}
                                <span>
                                    {capitalizeFirstLetter(currentSolution.lang.toLowerCase())}
                                </span>
                            </p>
                        </div>
                        <div className="flex items-center justify-start opacity-80">
                            <Tooltip
                                content="Download code"
                                placement="bottom"
                                className="text-[0.6rem] rounded-sm bg-primary-bgColorDark"
                            >
                                <div
                                    className="cursor-pointer mx-2 flex items-center justify-center"
                                    onClick={() => handleCodeDownload()}
                                >
                                    <MdDownload size={20} />
                                    <p className="ml-2">Download File</p>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </Paper>
            )}

            <Paper
                variant="outlined"
                className="p-4 m-auto rounded-md min-h-[20vh] w-full my-2"
            >
                <div>
                    <p className="py-2 font-semibold">Testcases Info</p>
                    <div className="w-full overflow-x-auto">
                        <table className="w-full text-sm text-left text-gray-500">
                            <thead className="text-xs text-white uppercase bg-gray-100 dark:bg-gray-700 ">
                                <tr>
                                    <th scope="col" className="py-3 px-6 text-center">
                                        Sub Task Name
                                    </th>
                                    <th scope="col" className="py-3 px-6 text-center">
                                        Result
                                    </th>
                                    <th scope="col" className="py-3 px-6 text-center">
                                        Sub Task Score
                                    </th>
                                    <th scope="col" className="py-3 px-6 text-center">
                                        Time Limit (s)
                                    </th>
                                    <th scope="col" className="py-3 px-6 text-center">
                                        Required
                                    </th>
                                </tr>
                            </thead>

                            {tLoader ? (
                                <tbody>
                                    <td>
                                        <Skeleton
                                            animation="pulse"
                                            variant="rectangular"
                                            width={'100%'}
                                            height={50}
                                            sx={{ marginBlock: '10px' }}
                                        />
                                    </td>
                                    <td>
                                        <Skeleton
                                            animation="pulse"
                                            variant="rectangular"
                                            width={'100%'}
                                            height={50}
                                            sx={{ marginBlock: '10px' }}
                                        />
                                    </td>
                                    <td>
                                        <Skeleton
                                            animation="pulse"
                                            variant="rectangular"
                                            width={'100%'}
                                            height={50}
                                            sx={{ marginBlock: '10px' }}
                                        />
                                    </td>
                                    <td>
                                        <Skeleton
                                            animation="pulse"
                                            variant="rectangular"
                                            width={'100%'}
                                            height={50}
                                            sx={{ marginBlock: '10px' }}
                                        />
                                    </td>
                                </tbody>
                            ) : (
                                <tbody>
                                    {currentSolution?.testCases.map((t, idx) => (
                                        <tr
                                            className={`border-b ${t.result === 'RE' ||
                                                t.result === 'WA' ||
                                                t.result === 'CE'
                                                ? 'bg-red-50'
                                                : t.result === 'PA' || t.result === 'TLE'
                                                    ? 'bg-yellow-100'
                                                    : 'bg-green-100'
                                                }`}
                                            key={t._id}
                                        >
                                            <th
                                                scope="row"
                                                className="py-4 px-6 text-center font-medium whitespace-nowrap text-black"
                                            >
                                                {t.name}
                                            </th>
                                            <td className="py-4 px-6 text-center">{t.result}</td>
                                            <td className="py-4 px-6 text-center">{t.score}</td>
                                            <td className="py-4 px-6 text-center">
                                                {t?.time.toFixed(2)}
                                            </td>
                                            <td className="py-4 px-6 text-center">
                                                {t.required ? 'Yes' : 'No'}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            </Paper>
        </>
    )
}
