import { CircularProgress, TextareaAutosize } from '@mui/material'
import React from 'react'
import { BsFillMarkdownFill } from 'react-icons/bs'
import { RenderQ } from '../QuestionRender/RenderQ'
import './field.css'
import { VscDiffRemoved } from 'react-icons/vsc'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const useStyles = {
    selected: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "400",
        padding: "5px 8px",
        backgroundColor: "var(--color-bg-dark)",
        color: "white",
        border: "0.5px solid rgb(0,0,0,0)"
    },
    unselected: {
        color: "var(--color-bg-dark)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "400",
        padding: "5px 8px",
        border: "0.5px solid var(--color-bg-dark)",
    }
}

export const Fields = ({ value, handleChange, handleSubmit, submitLoad, handleRemove, addInput, edit = false, handleExampleChange }) => {

    const [preview, setPreview] = React.useState({
        description: false,
        input: false,
        output: false,
        constraints: false,
    })
    const changePrev = (name, flag) => {
        setPreview((prev) => {
            return {
                ...prev, [name]: flag
            }
        })
    }
    return (
        <div className='fieldsContainer'>
            <div className="codeNameContainer">
                <span className="codeName">Name</span>
                <input
                    type="text"
                    name="name"
                    value={value.name}
                    onChange={handleChange}
                    className="codeNameText" />
            </div>
            <div className="inline">
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small">Type</InputLabel>
                    <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={value.type}
                        label="type"
                        name='type'
                        onChange={handleChange}
                    >
                        <MenuItem value={"CP"}>Competitive Coding</MenuItem>
                        <MenuItem value={"WEB"}>Frontend</MenuItem>
                        <MenuItem value={"SERVER"}>Backend</MenuItem>
                    </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small">Difficulty</InputLabel>
                    <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={value.difficulty}
                        label="difficulty"
                        name='difficulty'
                        onChange={handleChange}
                        disabled={edit}
                    >

                        <MenuItem value={"EASY"}>Easy</MenuItem>
                        <MenuItem value={"MEDIUM"}>Medium</MenuItem>
                        <MenuItem value={"HARD"}>Hard</MenuItem>
                        <MenuItem value={"VERY_HARD"}>Very Hard</MenuItem>
                    </Select>
                </FormControl>
                <div className="codeNameContainer">
                    <span className="codeName">Score</span>
                    <input
                        type={"number"}
                        name="score"
                        style={{ width: "18vw" }}
                        value={value.score}
                        onChange={handleChange}
                        className="codeNameText inlineText" />
                </div>
            </div>
            <div className="questionContainer">
                <div className="questionName">
                    <span
                        className="Qtitle"
                        style={preview.description ? useStyles.unselected : useStyles.selected}
                        onClick={() => changePrev('description', false)}
                    >
                        Question
                    </span>
                    <span
                        style={!preview.description ? useStyles.unselected : useStyles.selected}
                        onClick={() => changePrev('description', true)}
                        className="renderBtn"
                    >
                        Preview
                    </span>
                </div>
                <div className="questionText">
                    {preview.description ?
                        <RenderQ text={value.description} type="question" />
                        :
                        <>
                            <TextareaAutosize
                                name="description"
                                minRows={4}
                                className="descDisplay"
                                style={{ width: "100%", height: "45vh" }}
                                value={value.description}
                                onChange={handleChange}
                            />
                            <hr />
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "rgb(0,0,0,0.)"
                                }}
                                className="styling">
                                <BsFillMarkdownFill />&nbsp;&nbsp;Styling with Markdown is supported.
                            </div>
                        </>}
                </div>
            </div>
            <div className="inlineContainer">
                {/* constraints */}
                <div className="questionContainer">
                    <div className="questionName">
                        <span
                            className="Qtitle"
                            style={preview.constraints ? useStyles.unselected : useStyles.selected}
                            onClick={() => changePrev('constraints', false)}
                        >
                            Constraints
                        </span>
                        <span
                            style={!preview.constraints ? useStyles.unselected : useStyles.selected}
                            onClick={() => changePrev('constraints', true)}
                            className="renderBtn"
                        >
                            Preview
                        </span>
                    </div>
                    <div className="questionText">
                        {preview.constraints ?
                            <RenderQ text={value.constraints} type="input" />
                            :
                            <>
                                <TextareaAutosize
                                    name="constraints"
                                    minRows={4}
                                    className="descDisplay"
                                    style={{ width: "100%", height: "45vh" }}
                                    value={value.constraints}
                                    onChange={handleChange}
                                />
                                <hr />
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        color: "rgb(0,0,0,0.)"
                                    }}
                                    className="styling">
                                    <BsFillMarkdownFill />&nbsp;&nbsp;Styling with Markdown is supported.
                                </div>
                            </>}
                    </div>
                </div>
                {/* input */}
                <div className="questionContainer">
                    <div className="questionName">
                        <span
                            className="Qtitle"
                            style={preview.input ? useStyles.unselected : useStyles.selected}
                            onClick={() => changePrev('input', false)}
                        >
                            Input
                        </span>
                        <span
                            style={!preview.input ? useStyles.unselected : useStyles.selected}
                            onClick={() => changePrev('input', true)}
                            className="renderBtn"
                        >
                            Preview
                        </span>
                    </div>
                    <div className="questionText">
                        {preview.input ?
                            <RenderQ text={value.input} type={"input"} />
                            :
                            <>
                                <TextareaAutosize
                                    name="input"
                                    minRows={4}
                                    className="descDisplay"
                                    style={{ width: "100%", height: "45vh" }}
                                    value={value.input}
                                    onChange={handleChange}
                                />
                                <hr />
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        color: "rgb(0,0,0,0.)"
                                    }}
                                    className="styling">
                                    <BsFillMarkdownFill />&nbsp;&nbsp;Styling with Markdown is supported.
                                </div>
                            </>}
                    </div>
                </div>
                {/* output */}
                <div className="questionContainer">
                    <div className="questionName">
                        <span
                            className="Qtitle"
                            style={preview.output ? useStyles.unselected : useStyles.selected}
                            onClick={() => changePrev('output', false)}
                        >
                            Output
                        </span>
                        <span
                            style={!preview.output ? useStyles.unselected : useStyles.selected}
                            onClick={() => changePrev('output', true)}
                            className="renderBtn"
                        >
                            Preview
                        </span>
                    </div>
                    <div className="questionText">
                        {preview.output ?
                            <RenderQ text={value.output} type="input" />
                            :
                            <>
                                <TextareaAutosize
                                    name="output"
                                    minRows={4}
                                    className="descDisplay"
                                    style={{ width: "100%", height: "45vh" }}
                                    value={value.output}
                                    onChange={handleChange}
                                />
                                <hr />
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        color: "rgb(0,0,0,0.)"
                                    }}
                                    className="styling">
                                    <BsFillMarkdownFill />&nbsp;&nbsp;Styling with Markdown is supported.
                                </div>
                            </>}
                    </div>
                </div>
            </div>
            <div className="formPrize perInput">
                <div className="inputTitle">
                    Examples
                </div>
                <div className="subInputContainer">
                    {value.example.map((obj, idx) => {
                        return (
                            <div key={obj._id} className='subPrizeContainer' style={{ display: "block" }}>
                                <div className='perSubInput' style={{ display: "flex", flexDirection: "row" }}>
                                    <div className="perInput formDescription">
                                        <div className="inputTitle">
                                            Input
                                        </div>
                                        <textarea
                                            name="input"
                                            value={obj.input}
                                            id={`example ${idx}`}
                                            onChange={(e) => handleExampleChange(e, idx)}
                                            style={{ backgroundColor: "white", border: "1px solid var(--color-bg-dark)" }}
                                        />
                                    </div>
                                    <div className="perInput formDescription">
                                        <div className="inputTitle">
                                            Output
                                        </div>
                                        <textarea
                                            name="output"
                                            value={obj.output}
                                            id={`example ${idx}`}
                                            onChange={(e) => handleExampleChange(e, idx)}
                                            style={{ backgroundColor: "white", border: "1px solid var(--color-bg-dark)" }}
                                        />
                                    </div>
                                </div>
                                <div className='perSubInput'>
                                    <div className="perInput formDescription">
                                        <div className="inputTitle">
                                            Explaination
                                        </div>
                                        <textarea
                                            name="explanation"
                                            value={obj.explanation}
                                            id={`example ${idx}`}
                                            onChange={(e) => handleExampleChange(e, idx)}
                                            style={{ backgroundColor: "white", border: "1px solid var(--color-bg-dark)" }}
                                        />
                                    </div>
                                </div>
                                <VscDiffRemoved onClick={() => handleRemove("example", idx)} style={{ fontSize: "2em", margin: "12px", cursor: "pointer", color: "var(--color-accent)" }} />
                            </div>
                        )
                    })}
                </div>
                <div className='addBtn' onClick={() => { addInput("example") }}>Add</div>
            </div>
            <div className="submitBtn">

                {submitLoad ?
                    <div className="subBtn">
                        <CircularProgress style={{ width: '0.9em', height: "0.9em" }} />
                    </div> :
                    <div className="subBtn" onClick={() => handleSubmit()}>
                        <span>Submit</span>
                    </div>
                }
            </div>
        </div>
    )
}
