import './App.css';
import 'katex/dist/katex.min.css'
import SSO from '@code-skills-community/sso-react-client';
import { Navbar } from './components/navbar/Navbar'
import { Home } from './pages/home/Home';
import { Route, Routes } from 'react-router-dom';
import Notfound from './pages/notfound/Notfound';
import { Createcontest } from './pages/createContest/Createcontest';
import { Edit } from './pages/edit/Edit';
import { Contest } from './pages/contestInfo/Contest';
import { Question } from './pages/questions/Question';
import { Createquestion } from './pages/createQuestion/Createquestion';
import { EditQ } from './pages/editQuestion/EditQ';
import { SnackbarContext } from './context/SnackbarContext';
import { CreateTest } from './pages/testUpload/CreateTest';
import { SnackbarProvider } from 'notistack';
import { ViewQuestion } from './pages/questionInfo/ViewQuestion'
import Editorial from './pages/editorial/Editorial';
import Announcement from './pages/announcements/Announcement';
import { EnhancedTable } from './pages/usersList/Users'
import { LoginWrapper } from './context/loginContext';
import { EnhancedSubmissionTable } from './pages/submissions/Submissions';
import { Solution } from './pages/solution/Solution';

//SSO.Context
// process.env.REACT_APP_BASE_URL

function App() {
  return (
    <SSO.Provider>
      <SnackbarProvider maxSnack={3}>
        <SnackbarContext>
          <LoginWrapper>
            <div className="App">
              <Navbar />
              <Routes>
                <Route exact path='/' element={<Home />} />
                <Route exact path='/create' element={<Createcontest />} />
                <Route exact path='/edit/:id' element={<Edit />} />
                <Route exact path='/view/:id' element={<Contest />} />
                <Route exact path='/questions/:id' element={<Question />} />
                <Route exact path='/questions/:id/editorial/' element={<Editorial />} />
                <Route exact path='/questions/create/:id' element={<Createquestion />} />
                <Route exact path='/questions/view/:id' element={<ViewQuestion />} />
                <Route exact path='/questions/edit/:id' element={<EditQ />} />
                <Route exact path='/test/create/:type/:id' element={<CreateTest />} />
                <Route exact path='/users' element={<EnhancedTable />} />
                <Route exact path='/submissions' element={<EnhancedSubmissionTable />} />
                <Route exact path='/submissions/:id' element={<Solution />} />
                <Route exact path='/editorial/' element={<Editorial />} />
                <Route exact path='/announcements/:id' element={<Announcement />} />
                <Route path='*' element={<Notfound />} />
              </Routes>
            </div>
          </LoginWrapper>
        </SnackbarContext>
      </SnackbarProvider>
    </SSO.Provider>
  );
}

export default App;
