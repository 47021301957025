import React from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { coldarkDark as dark } from 'react-syntax-highlighter/dist/cjs/styles/prism'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import './display.css'
import RemarkMathPlugin from 'remark-math'
import rehypeKatex from 'rehype-katex'

export const RenderQ = ({ text, type }) => {
  return (
    <div className='proseContainer'>
      <ReactMarkdown
        className={`p-2 prose mdDisplay ${type === "question" && "max-w-full"}`}
        children={text}
        remarkPlugins={[remarkGfm, RemarkMathPlugin]}
        rehypePlugins={[rehypeKatex]}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '')
            return !inline && match ? (
              <SyntaxHighlighter
                children={String(children).replace(/\n$/, '')}
                style={dark}
                language={match[1]}
                PreTag="div"
                {...props}
              />
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            )
          },
        }}
      /></div>
  )
}
