import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import React from 'react'

export const PopUp = ({ handleChange, forFile, isFile, handleFileClose, type }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Dialog
            fullScreen={fullScreen}
            open={isFile}
            onClose={handleFileClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {"Upload"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <label htmlfor="images" className="drop-container">
                        <input
                            type="file"
                            id="images"
                            name={forFile}
                            accept={type === "CP" ? ".txt" : forFile === "input" ? ".json" : "image/*"}
                            onChange={handleChange}
                            required
                        />
                    </label>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => handleFileClose()}>
                    Upload
                </Button>
            </DialogActions>
        </Dialog>
    )
}
