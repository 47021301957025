import { getLevelData, User } from "@code-skills-community/react-user";
import React from "react";
import {
  Backdrop,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import SSO from "@code-skills-community/sso-react-client";
import TableContainer from "@mui/material/TableContainer";
import { CodeSkillProfileApi, codeSkillsApi } from "../../utils/axios_config";
import { useSnackbar } from "notistack";
import { MdOutlineArrowBackIosNew, MdOpenInNew } from "react-icons/md";
import { GrNext, GrRefresh } from "react-icons/gr";
import { Link } from "react-router-dom";
import moment from "moment";
import { FcFilledFilter } from "react-icons/fc";
import {
  LANGUAGES,
  SOLUTION_RESULT,
  SOLUTION_STATUS,
} from "../../utils/helper";

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// sorting
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    align: "left",
  },
  {
    id: "time",
    numeric: false,
    disablePadding: false,
    label: "Time",
    align: "left",
  },
  {
    id: "language",
    numeric: false,
    disablePadding: false,
    label: "Language",
    align: "left",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
    align: "left",
  },
  {
    id: "submission",
    numeric: false,
    disablePadding: false,
    label: "View Submission",
    align: "right",
  },
];

export function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, idx) => {
          return (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{ padding: "1rem" }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export function EnhancedSubmissionTable() {
  const { getAuthToken, isLoggedIn } = React.useContext(SSO.Context);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(true);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("rank");
  const [rows, setRows] = React.useState([]);
  // notistack
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = React.useState("");
  const [lang, setLang] = React.useState("");
  const [result, setResult] = React.useState("");
  const [type, setType] = React.useState("");
  const [openStatus, setOpenStatus] = React.useState(false);
  const [openLang, setOpenLang] = React.useState(false);
  const [openType, setOpenType] = React.useState(false);
  const [openResult, setOpenResult] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    getAuthToken(true).then((token) => {
      codeSkillsApi
        .get(`solutions?page=${page}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          setLoading(false);
          setRows(res.data.solutions);
        })
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: "error" });
          console.log("Error:", error.message);
        });
    });
  }, [page]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleLangChange = (event) => {
    setLang(event.target.value);
  };
  const handleResultChange = (event) => {
    setResult(event.target.value);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleClose = (type) => {
    if (type === "status") {
      setOpenStatus(false);
    }
    if (type === "type") {
      setOpenType(false);
    }
    if (type === "result") {
      setOpenResult(false);
    }

    if (type === "lang") {
      setOpenLang(false);
    }
  };

  const handleOpen = (type) => {
    if (type === "status") {
      setOpenStatus(true);
    }
    if (type === "type") {
      setOpenType(true);
    }
    if (type === "result") {
      setOpenResult(true);
    }

    if (type === "lang") {
      setOpenLang(true);
    }
  };

  return (
    <>
      <Paper
        variant="outlined"
        className="w-[80%] p-4 m-auto rounded-md mt-4 mb-4"
      >
        <div className="flex items-center justify-start">
          <div className="flex items-center justify-center p-1 px-2 border rounded-md shadow-md mx-2">
            <div
              className="flex cursor-pointer items-center justify-center text-lg text-white relative"
              onClick={() => setOpenStatus(!openStatus)}
            >
              <FcFilledFilter />
              <p className="ml-2 text-black">
                {status === "" ? "Status" : status}
              </p>
              <div className="absolute z-[-10]">
                <Select
                  open={openStatus}
                  onClose={() => handleClose("status")}
                  onOpen={() => handleOpen("status")}
                  value={status}
                  label="Status"
                  onChange={handleStatusChange}
                  inputProps={{
                    MenuProps: {
                      MenuListProps: {
                        sx: {
                          backgroundColor: "#0f1527be",
                        },
                      },
                    },
                  }}
                  style={{
                    visibility: "hidden",
                  }}
                >
                  <MenuItem value="">
                    <em className="text-white">None</em>
                  </MenuItem>
                  {SOLUTION_STATUS.map((s, idx) => (
                    <MenuItem value={s} key={idx}>
                      <span className="text-[#0BB6A3]">{s}</span>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center p-1 px-2 border rounded-md shadow-md mx-2">
            <div
              className="flex cursor-pointer items-center justify-center text-lg text-white relative"
              onClick={() => setOpenType(!openType)}
            >
              <FcFilledFilter />
              <p className="ml-2 text-black">{type === "" ? "Type" : type}</p>
              <div className="absolute z-[-10]">
                <Select
                  open={openType}
                  onClose={() => handleClose("type")}
                  onOpen={() => handleOpen("type")}
                  value={type}
                  label="Type"
                  onChange={handleTypeChange}
                  inputProps={{
                    MenuProps: {
                      MenuListProps: {
                        sx: {
                          backgroundColor: "#0f1527be",
                        },
                      },
                    },
                  }}
                  style={{
                    visibility: "hidden",
                  }}
                >
                  <MenuItem value="">
                    <em className="text-white">None</em>
                  </MenuItem>

                  <MenuItem value={"CP"}>
                    <span className="text-[#0BB6A3]">CP</span>
                  </MenuItem>
                  <MenuItem value={"WEB"}>
                    <span className="text-[#0BB6A3]">WEB</span>
                  </MenuItem>
                </Select>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center p-1 px-2 border rounded-md shadow-md mx-2">
            <div
              className="flex cursor-pointer items-center justify-center text-lg text-white relative"
              onClick={() => setOpenLang(!openLang)}
            >
              <FcFilledFilter />
              <p className="ml-2 text-black">
                {lang === "" ? "Language" : lang}
              </p>
              <div className="absolute z-[-10]">
                <Select
                  open={openLang}
                  onClose={() => handleClose("lang")}
                  onOpen={() => handleOpen("lang")}
                  value={lang}
                  label="Language"
                  onChange={handleLangChange}
                  inputProps={{
                    MenuProps: {
                      MenuListProps: {
                        sx: {
                          backgroundColor: "#0f1527be",
                        },
                      },
                    },
                  }}
                  style={{
                    visibility: "hidden",
                  }}
                >
                  <MenuItem value="">
                    <em className="text-white">None</em>
                  </MenuItem>
                  {LANGUAGES.map((l, idx) => (
                    <MenuItem value={l} key={idx}>
                      <span className="text-[#0BB6A3]">{l}</span>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center p-1 px-2 border rounded-md shadow-md mx-2">
            <div
              className="flex cursor-pointer items-center justify-center text-lg text-white relative"
              onClick={() => setOpenResult(!openResult)}
            >
              <FcFilledFilter />
              <p className="ml-2 text-black">
                {result === "" ? "Result" : result}
              </p>
              <div className="absolute z-[-10]">
                <Select
                  open={openResult}
                  onClose={() => handleClose("result")}
                  onOpen={() => handleOpen("result")}
                  value={result}
                  label="Result"
                  onChange={handleResultChange}
                  inputProps={{
                    MenuProps: {
                      MenuListProps: {
                        sx: {
                          backgroundColor: "#0f1527be",
                        },
                      },
                    },
                  }}
                  style={{
                    visibility: "hidden",
                  }}
                >
                  <MenuItem value="">
                    <em className="text-white">None</em>
                  </MenuItem>
                  {SOLUTION_RESULT.map((r, idx) => (
                    <MenuItem value={r}>
                      <span className="text-[#0BB6A3]">{r}</span>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </Paper>

      <Paper
        className="w-[80%] p-4 m-auto rounded-md mt-4 mb-4"
        style={{
          backgroundColor: "rgba(255,255,255, 0.9)",
          color: "white !important",
        }}
      >
        <TableContainer>
          <Table
            sx={{
              minWidth: 100,
              color: "black",
              border: 0,
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            {loading && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
              />
            )}
            <TableBody sx={{ width: "100%" }}>
              {stableSort(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  const userData = getLevelData(row.level);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      key={row._id}
                      sx={{
                        backgroundColor:
                          index % 2 === 0 ? "rgba(0,0,0,0.08)" : "",
                        transition: "all 500ms ease",
                        cursor: "pointer",
                        [`&:hover`]: {
                          backgroundColor: "rgba(0,0,0,0.05)",
                        },
                      }}
                    >
                      <TableCell
                        id={labelId}
                        align="center"
                        scope="row"
                        sx={{
                          borderTopLeftRadius: "6px",
                          borderBottomLeftRadius: "6px",
                          color: "black",
                          minWidth: "20%",
                        }}
                      >
                        <User userId={row.userId} />
                      </TableCell>

                      <TableCell
                        id={labelId}
                        align="left"
                        scope="row"
                        sx={{
                          color: "black",
                          textOverflow: "ellipsis",
                          cursor: "pointer",
                          minWidth: "15%",
                        }}
                      >
                        {moment(row.createdAt).format("MMM-DD-YYYY | hh:mm:ss")}
                      </TableCell>

                      <TableCell
                        id={labelId}
                        align="left"
                        scope="row"
                        sx={{
                          color: "black",
                          textOverflow: "ellipsis",
                          cursor: "pointer",
                        }}
                      >
                        <p className="font-bold">{row.lang}</p>
                      </TableCell>

                      <TableCell
                        id={labelId}
                        align="left"
                        scope="row"
                        sx={{
                          color: "black",
                          textOverflow: "ellipsis",
                          cursor: "pointer",
                        }}
                      >
                        {row.status === "Evaluated" ? (
                          <p
                            className={`${
                              row.result === "AC"
                                ? "text-green-700"
                                : row.result === "PA" || row.result === "TLE"
                                ? "text-accent"
                                : "text-[#FF3340]"
                            }   font-bold cursor-pointer`}
                          >
                            {row.result}
                          </p>
                        ) : (
                          <div className="m-auto flex items-center justify-start">
                            <GrRefresh className="evaluating" />
                          </div>
                        )}
                      </TableCell>

                      <TableCell
                        id={labelId}
                        align="right"
                        scope="row"
                        sx={{
                          color: "black",
                          textOverflow: "ellipsis",
                          cursor: "pointer",
                          width: "20%",
                          borderTopRightRadius: "6px",
                          borderBottomRightRadius: "6px",
                        }}
                      >
                        <Link
                          to={`/submissions/${row._id}`}
                          className="flex items-center justify-end"
                        >
                          <MdOpenInNew />
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="w-full flex justify-end items-center p-2">
          <div className="w-max flex p-2 items-center justify-center">
            {page > 1 && (
              <div
                className="h-[35px] w-[35px] flex items-center justify-center text-lg p-2 m-1 shadow-[0px_0px_5px_rgba(0,0,0,0.15)] cursor-pointer"
                onClick={() => setPage((page) => page - 1)}
              >
                <MdOutlineArrowBackIosNew />
              </div>
            )}
            <div className="h-[35px] w-[35px] flex items-center justify-center text-lg p-2 m-1 shadow-[0px_0px_5px_rgba(0,0,0,0.15)]">
              {page}
            </div>

            {rows.length % 25 === 0 && (
              <div
                className="h-[35px] w-[35px] flex items-center justify-center text-lg p-2 m-1 shadow-[0px_0px_5px_rgba(0,0,0,0.15)] cursor-pointer"
                onClick={() => setPage((page) => page + 1)}
              >
                <GrNext />
              </div>
            )}
          </div>
        </div>
      </Paper>
    </>
  );
}
