import {
  Button,
  
  Paper,
  
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSnackbar } from 'notistack';
import SSO from "@code-skills-community/sso-react-client";
import { codeSkillsApi } from "../../utils/axios_config";
import { useParams } from "react-router-dom";
import { data } from "autoprefixer";



const Announcement = () => {
  const { id } = useParams();
  const { getAuthToken } = React.useContext(SSO.Context);

  const [announcementList, setAnnouncementList] = useState([]);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();


 
  useEffect(()=>{
    getAuthToken(true).then((token)=>{
      codeSkillsApi.get(`contest/${id}/announcement?page=1`,{
        headers:{
          Authorizarion:token
        }
      }).then((res)=>{
        setLoading(false);
        // console.log(res);
        setAnnouncementList(res.data.announcements)
      })
      .catch((error)=>{
        setLoading(false)
        enqueueSnackbar(error, { variant: "error" });
          console.log("Error:", error);
      })
    })
  },[])
  const handleMakeAnnouncement = ()=>{
    // console.log(title)
    // console.log(body)
    if(title == '' || body==''){
      enqueueSnackbar("Enter title and body", { variant: "error" });
      return;
    }
    data = {title,body};
    getAuthToken(true).then((token)=>{
      codeSkillsApi.post(`contest/${id}/announcement`,data,{
        headers:{Authorization:token}
      }).then((res)=>{
        // console.log(res)
        enqueueSnackbar("Announcement added successfully", {variant:"success"})
        setAnnouncementList([res.data.announcement, ...announcementList])
      }).catch(err=>console.log(err))
    })
  }
  const handleDelete = (a_id) => {
    if(window.confirm("Are you sure you want to delete this announcement ? ")){
      getAuthToken(true).then((token) => {
        codeSkillsApi.delete(`contest/${id}/announcement/${a_id}`,{
          headers: {
              Authorization: token
          }
      } )
      .then(res => {
        // console.log(res)
        if(res.data.err==false){
          enqueueSnackbar("Announcement deleted successfully", {variant:"success"})
          const newList = announcementList.filter((ele)=>ele._id!=a_id)
          setAnnouncementList(newList)
        }else{
          enqueueSnackbar("Announcement could not be deleted due to some problem", {variant:"info"})

        }
      }).catch(err=>{
        enqueueSnackbar(err, {variant:"error"})
        console.log(err)

      })
      })
    }
  }
  return (
    <>
      <div className="m-5 flex">
        <Paper elevation={3} className="m-5 p-6 flex flex-col w-1/4 sticky bottom-0 h-full">
          <h1 className="font-bold text-xl mb-3 text-center">
            Add Announcement
          </h1>
          <label
            for="exampleFormControlInput1"
            class="form-label inline-block mb-1  text-gray-700 font-bold"
          >
            Title
          </label>
          <input
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            type="text"
            className="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
        mb-3
      "
            id="exampleFormControlInput1"
          />
          <label
            for="exampleFormControlTextarea1"
            class="form-label inline-block mb-1 text-gray-700 font-bold"
          >
            Description
          </label>
          <textarea
          value={body}
          onChange= {(event)=>setBody(event.target.value)}
            className="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
            id="exampleFormControlTextarea1"
            rows="10"
            placeholder="Your announcement"
          ></textarea>{" "}
          <div className="m-5 flex justify-center">
            <Button variant="contained" onClick={handleMakeAnnouncement}>Make Announcement</Button>
          </div>
        </Paper>
        <Paper elevation={3} className="m-5 p-6 flex flex-col w-3/4 h-auto">
          <h1 className="h-5 text-3xl font-extrabold font-mono text-center">
            📣 Announcements
          </h1>
          <div>
          {loading ? <p>loading...</p> : 
            announcementList.length == 0 ? (<div className="mt-10 flex justify-center text-center item-center">

            <p>No announcements made!</p>
            </div>
            ):  (announcementList.map((ele) => (
              <div key={ele._id} className="mt-5 p-3 bg-slate-50 border border-gray-400 rounded-md ">
                <h1 className="text-xl font-bold">{ele.title}</h1>
                <p className="w-4/5">{ele.body}</p>
                <div className="flex justify-end"><Button size="small" variant="contained" onClick={()=>handleDelete(ele._id)}>Delete</Button></div>
              </div>
            )))}
          </div>
        </Paper>
      </div>
    </>
  );
};

export default Announcement;
