import './contestcard.css';
import React, { useContext } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { MdOutlinePeopleOutline, MdOutlineAttachMoney } from 'react-icons/md';
import SSO from '@code-skills-community/sso-react-client';
import { codeSkillsApi } from '../../utils/axios_config';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { BsQuestionSquareFill } from 'react-icons/bs';
import { TbSpeakerphone } from 'react-icons/tb'
import { CgExtensionAdd } from 'react-icons/cg';
import ReactTooltip from 'react-tooltip';
import { useSnackbar } from 'notistack';

export const Homecard = (props) => {
    const { getAuthToken } = useContext(SSO.Context);
    const [loading, setLoading] = React.useState(false)
    // notistack
    const { enqueueSnackbar } = useSnackbar();

    const deleteContest = () => {
        if (!window.confirm("Are you sure you want to delete ?"))
            return
        setLoading(true);
        getAuthToken(true).then(token => {
            console.log(token)
            codeSkillsApi
                .delete(`contest/${props.data._id}`, {
                    headers: {
                        Authorization: token
                    }
                })
                .then(data => {
                    setLoading(false);
                    enqueueSnackbar("Deleted Successfully", { variant: 'success' });
                    return window.location.reload();
                })
                .catch(error => {
                    enqueueSnackbar(error, { variant: 'error' });
                    console.log("Error:", error);
                })
        });
    }

    var startDate = new Date(Date.parse(props.data.start)).toString().split(" ");
    var endDate = new Date(Date.parse(props.data.end)).toString().split(" ");

    return (
        <>
            <ReactTooltip />
            <div className="imgBodyContainer">
                <Link to={`/view/${props.data._id}`}>
                    <div className='cardImage'
                        style={{
                            background: `url('${process.env.REACT_APP_imageAPI_BASE}/contest-images/${props.data._id}.jpg')`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            margin: 0,
                        }}>
                    </div>
                </Link>
                <div className='cardContainer'>

                    <div className="cardBody">
                        <Link to={`/view/${props.data._id}`}>
                            <div className="contestHeader">
                                <div className="contestCode">
                                    <span className="cardHead">
                                        Code :&nbsp;</span>
                                    <span className="boldThis">
                                        {props.data.code}
                                    </span>
                                </div>
                            </div>
                            <div className="contestName">
                                {props.data.name}
                            </div>
                            <div className="contestFooter">
                                <div className="contestStart contestDate">
                                    <span className="cardHead">Starts at :</span>
                                    <div className="contestDay">
                                        <span className='boldThis'>{startDate.slice(1, 4).join(" ")}</span>
                                    </div>
                                    <div className="contestTime boldThis">
                                        {startDate[4]}
                                    </div>
                                </div>
                                <div className="contestEnd contestDate">
                                    <span className="cardHead">Ends at :</span>
                                    <div className="contestDay">
                                        <span className="boldThis"> {endDate.slice(1, 4).join(" ")}
                                        </span>
                                    </div>
                                    <div className="contestTime boldThis">
                                        {endDate[4]}

                                    </div>
                                </div>
                                <div>
                                    <div className="contestPrize footerChild">
                                        <MdOutlineAttachMoney style={{ fontSize: "1.4em" }} /> <span className="boldThis">{props.data.prizepool}</span>
                                    </div>
                                    <div className="contestParticipant footerChild">
                                        <MdOutlinePeopleOutline style={{ fontSize: "1.4em" }} /> <span className="boldThis">{props.data.participants}</span>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="editsOpts">
                        <Link to={`/edit/${props.data._id}`}>
                            <FiEdit data-tip="Edit" data-place="right" data-effect="solid" style={{ fontSize: "1.15em" }} />
                        </Link>
                        {loading ? <CircularProgress style={{ width: "3vh", height: "3vh" }} /> : <RiDeleteBin5Line data-tip="Delete" data-place="right" data-effect="solid" style={{ fontSize: "1.25em", cursor: 'pointer' }} onClick={() => { deleteContest() }} />}
                        <Link to={`/questions/${props.data._id}`}>
                            <BsQuestionSquareFill data-tip="Questions" data-place="right" data-effect="solid" style={{ fontSize: '1em' }} />
                        </Link>
                        <Link to={`/questions/create/${props.data._id}`}>
                            <CgExtensionAdd data-tip="Add Questions" data-place="right" data-effect="solid" style={{ fontSize: '1.45em' }} />
                        </Link>
                        <Link to={`/announcements/${props.data._id}`}>
                            <TbSpeakerphone data-tip="Add Announcements" data-place="right" data-effect="solid" style={{ fontSize: '1.45em' }} />
                        </Link>
                    </div>
                </div>
            </div>

        </>
    )
}
