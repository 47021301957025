import React, { useContext } from 'react';
import SSO from '@code-skills-community/sso-react-client';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';
import './FileUpload.css'
import { codeSkillsApi } from '../../utils/axios_config';
import FormData from 'form-data'
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';

export const FileUpload = ({ isFile, handleFileClose, id }) => {
    const { getAuthToken } = useContext(SSO.Context);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [submit, setSub] = React.useState(false);

    // notistack
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = () => {

        if (selectedFile === null) {
            enqueueSnackbar("No File is Uploaded", { variant: 'error' });
            return
        }

        setSub(true);

        const data = new FormData();
        data.append('image', selectedFile, selectedFile.name);
        getAuthToken(true).then(token => {
            codeSkillsApi
                .post(`contest/upload-image/${id}`, data,
                    {
                        headers: {
                            Authorization: token
                        }
                    })
                .then(data => {
                    setSub(false);
                    if (!data.data.err) {
                        enqueueSnackbar("Successfully Created", { variant: 'success' })
                        return window.location.replace(window.location.protocol + "//" + window.location.host.split(":")[0] + ":" + window.location.port);
                    }
                    throw new Error({ message: 'Something went wrong' });
                })
                .catch(error => {
                    enqueueSnackbar(error.message, { variant: 'error' });
                    console.log("Error:", error)
                })
        });
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={isFile}
            onClose={handleFileClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {"Upload"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <label htmlfor="images" className="drop-container">
                        <input
                            type="file"
                            id="images"
                            accept="image/*"
                            onChange={(e) => setSelectedFile(e.target.files[0])}
                            required
                        />
                    </label>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    disabled={submit}
                    onClick={() => {
                        handleSubmit()
                        return handleFileClose
                    }}>
                    {submit ? <CircularProgress style={{ width: "0.9em", height: "0.9em" }} /> : <span>Upload</span>}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
