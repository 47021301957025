import React, { useEffect, useRef, useState } from "react";
import { FaMarkdown } from "react-icons/fa";
import autosize from "autosize";
import Previewer from "./Previewer";

const Editor = ({ text, setText }) => {
  const [preview, setPreview] = useState(false);
  const textareaRef = useRef(null);

  useEffect(() => {
    autosize(textareaRef.current);
  }, [text]);

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  return (
    <div className="relative w-full px-2 py-3 border rounded-md">
      <div className="flex items-baseline justify-start">
        <p
          className={`${
            !preview
              ? "border-2 border-b-0 border-gray-600 githubTabClone border-opacity-60"
              : "border-r-0"
          }  px-2 py-1  cursor-pointer rounded-t-md `}
          onClick={() => setPreview(false)}
        >
          Write
        </p>
        <p
          className={`${
            preview
              ? "border-2 border-b-0 border-gray-600 githubTabClone border-opacity-60"
              : "border-0"
          }  px-2 py-1 cursor-pointer rounded-t-md`}
          onClick={() => setPreview(true)}
        >
          Preview
        </p>
      </div>
      <div className={`py-1 px-2 border-2 w-full border-gray-600`}>
        {preview ? (
          <Previewer text={text} discussion />
        ) : (
          <textarea
            className={`w-full min-h-[6rem]  max-h-96 text-sm border border-opacity-30 rounded-sm bg-transparent text-black editorOutput`}
            value={text}
            onChange={(e) => handleTextChange(e)}
            ref={textareaRef}
          />
        )}
        {!preview && (
          <div className="flex items-center justify-start">
            <FaMarkdown />
            <p className="ml-2 text-xs opacity-80">
              Styling with Markdown is supported
            </p>
          </div>
        )}
      </div>

      <div className="absolute right-0 bottom-0"></div>
    </div>
  );
};

export default Editor;
