import React from "react";
import { useParams } from "react-router-dom";
import { codeSkillsApi } from "../../utils/axios_config";
import SSO from "@code-skills-community/sso-react-client";
import { TestFields } from "../../components/TestCreate/TestFields";
import FormData from "form-data";
import { useSnackbar } from "notistack";

export const CreateTest = () => {
  const { type, id } = useParams();
  const { getAuthToken } = React.useContext(SSO.Context);

  const [value, setValue] = React.useState({
    name: "",
    required: false,
    outputNo: false,
    input: null,
    output: null,
    score: 0,
    time: 0,
  });

  // submit loading
  const [subLoad, setSubLoad] = React.useState(false);
  const triggerSub = () => setSubLoad((old) => !old);

  //notistack
  const { enqueueSnackbar } = useSnackbar();

  function handleChange(event) {
    //destruct the event.target object
    var { name, value, type, checked } = event.target;
    //checked and unchecked for check box
    if (name === "input" || name === "output") value = event.target.files[0];
    setValue((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }

  const checkRequired = () => {
    let keys = Object.keys(value);
    const errorKeys = keys.filter((obj) => {
      return obj === "score" ||
        obj === "input" ||
        obj === "output" ||
        obj === "outputNo"
        ? false
        : value[obj].length !== 0
        ? false
        : true;
    });
    value["score"] <= 0 && errorKeys.push("score");
    value["input"] == null && errorKeys.push("Input");
    if (type === "CP") value["output"] == null && errorKeys.push("Output");
    if (errorKeys.length !== 0) {
      enqueueSnackbar(
        "Please fill " + errorKeys.join(" , ").toUpperCase() + " fields",
        { variant: "error" }
      );
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    triggerSub();
    checkRequired() &&
      getAuthToken(true).then((token) => {
        const formdata = new FormData();
        formdata.append("name", value.name);
        formdata.append("score", value.score);
        formdata.append("required", value.required);
        formdata.append("input", value.input, value.input.name);
        if (type === "CP") {
          formdata.append("time", value.time);
          formdata.append("outputNo", value.outputNo);
        }
        if (value.output) {
          formdata.append("output", value.output, value.output.name);
        }

        codeSkillsApi
          .post(`testcase/${type.toLowerCase()}/${id}`, formdata, {
            headers: {
              Authorization: token,
            },
          })
          .then((data) => {
            if (!data.data.err) {
              setSubLoad(false);
              enqueueSnackbar("Created", { variant: "success" });
            }
            throw new Error({ message: "Something went wrong" });
          })
          .catch((error) => {
            setSubLoad(false);
            console.log("Error:", error);
            enqueueSnackbar(
              error.response.data.error.message || error.message,
              { variant: "error" }
            );
          });
      });
  };

  return (
    <div className="createBody">
      <TestFields
        value={value}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        submitLoad={subLoad}
        type={type}
      />
    </div>
  );
};
