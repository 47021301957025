import { CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Fields from "../../components/Editorial/Fields";
import { useParams } from "react-router-dom";
import { codeSkillsApi } from "../../utils/axios_config";
import SSO from "@code-skills-community/sso-react-client";

const initialValue = {
  hints: [{ value: "" }],
  description: "👈 Write a description",
  video: "",
};

const Editorial = () => {
  const { id: questionId } = useParams();
  const { getAuthToken } = useContext(SSO.Context);

  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(initialValue);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const token = await getAuthToken();

        const res = await codeSkillsApi.get(
          `question/${questionId}/editorial`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        const data = res?.data.editorial;
        console.log({ data })
        if (data) {
          setValue({
            hints: data.hints.map((e) => ({ value: e })),
            description: data.description,
            video: data.video,
          });
          setIsUpdate(true);
        }
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    };

    getData();
  }, [questionId, getAuthToken]);

  return (
    <div className="createBody">
      {loading ? (
        <CircularProgress />
      ) : (
        <Fields
          questionId={questionId}
          initialValues={value}
          isUpdate={isUpdate}
        />
      )}
    </div>
  );
};

export default Editorial;
