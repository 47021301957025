import SSO from '@code-skills-community/sso-react-client';
import React from 'react';

const LoginRedirect = React.createContext();

export function useLogin() {
    return React.useContext(LoginRedirect)
}

export function LoginWrapper({ children }) {
    const { isLoggedIn, getAuthToken } = React.useContext(SSO.Context);
    const [loading, setLoading] = React.useState(true);
    // redirecting to home

    const checkPermissions = () => {
        getAuthToken(true)
            .then(token => {
                const data = JSON.parse(atob(token.split('.')[1])).permissions;
                let flag = true;
                for (let i = 0; i < data.length; ++i) {
                    if (data[i] === "super_admin") {
                        flag = false;
                        break;
                    }
                }
                (flag) ? window.location.replace("https://www.codestrike.dev/") : setLoading(false);
            })
        // .catch(() => {
        //     window.location.replace("https://www.codestrike.dev/");
        // })
    }

    React.useEffect(() => {
        // (!isLoggedIn) && window.location.replace("https://www.codestrike.dev/")
        checkPermissions();
    }, [])

    return (
        <LoginRedirect.Provider value={isLoggedIn}>
            {/* {!loading && children} */}
            {children}
        </LoginRedirect.Provider>
    )
}
