import SSO from '@code-skills-community/sso-react-client';
import { CircularProgress } from '@mui/material';
import React from 'react'
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { CgExtensionAdd } from 'react-icons/cg'
import { FaLeanpub } from "react-icons/fa";
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { codeSkillsApi } from '../../utils/axios_config';
import { useSnackbar } from 'notistack';

export const QuestionCard = (props) => {

    const { getAuthToken } = React.useContext(SSO.Context);
    const [loading, setLoading] = React.useState(false)
    const { enqueueSnackbar } = useSnackbar();

    const deleteContest = () => {

        if (!window.confirm("Are you sure you want to delete ? "))
            return

        getAuthToken(true).then(token => {
            console.log(token)
            codeSkillsApi
                .delete(`question/${props.data._id}`, {
                    headers: {
                        Authorization: token
                    }
                })
                .then(data => {
                    enqueueSnackbar("Deleted Successfully", { variant: 'success' });
                    window.location.reload();
                })
                .catch(error => {
                    enqueueSnackbar(error, { variant: 'error' });
                    console.log("Error:", error)
                })
        });
    }

    return (
        <>
            <ReactTooltip />
            <div className='cardContainer' style={{ marginTop: "2vh", minHeight: "max-content", width: "20vw" }}>
                <div className="cardBody">
                    <Link to={`/questions/view/${props.data._id}`}>
                        <div className="contestName">
                            {props.data.name}
                        </div>
                        <div className="contestFooter">
                            <div className="contestPrize footerChild">
                                <span className="cardHead">Score :&nbsp;</span>
                                <span className='boldThis'>{props.data.score}</span>
                            </div>
                            <div>
                                <div className="contestPrize footerChild">
                                    <span className="cardHead">Type :&nbsp;</span>
                                    <span className="boldThis">{props.data.type}</span>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="editsOpts">
                    <Link to={`/questions/edit/${props.data._id}`}>
                        <FiEdit data-tip="Edit" data-place="right" data-effect="solid" style={{ fontSize: "1.15em" }} />
                    </Link>
                    {loading ? <CircularProgress style={{ width: "3vh", height: "3vh" }} /> : <RiDeleteBin5Line data-tip="Delete" data-place="right" data-effect="solid" style={{ fontSize: "1.25em", cursor: 'pointer' }} onClick={() => {
                        setLoading(true)
                        deleteContest()
                    }} />}
                    <Link to={`/test/create/${props.data.type}/${props.data._id}`}>
                        <CgExtensionAdd data-tip="Add TestCase" data-place="right" data-effect="solid" style={{ fontSize: '1.45em' }} />
                    </Link>
                    <Link to={`/questions/${props.data._id}/editorial`}>
                        <FaLeanpub data-tip="Editorial" data-place="right" data-effect="solid" style={{ fontSize: '1.45em' }} />
                    </Link>
                </div>
            </div>


        </>
    )
}