import React from 'react'
import SSO from '@code-skills-community/sso-react-client';
import { useParams } from 'react-router-dom';
import { codeSkillsApi } from '../../utils/axios_config';
import { CircularProgress } from '@mui/material';
import { Fields } from '../../components/QuestionCreate/Fields';
import { useSnackbar } from 'notistack';

const initialValue = {
    name: "",
    description: "",
    type: "",
    input: "",
    output: "",
    constraints: "",
    score: 0,
    example: []
}

const toSkip = (key) => {
    let data = [
        "isSolved",
        "totalSolutions",
        "correctSolutions",
        "accuracy",
        "isListed",
        "__v",
        "contest",
        "allowedLanguages"
    ]

    let flag = false;

    flag = data.some((d) => d === key)

    return flag
}

export const EditQ = () => {

    const { getAuthToken } = React.useContext(SSO.Context);

    const { id } = useParams();
    const [loading, setLoading] = React.useState(true);

    // storing initial values to compare
    const [init, setInit] = React.useState(initialValue);
    const [value, setValue] = React.useState(initialValue);

    // notistack
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        getAuthToken(true).then(token => {
            codeSkillsApi
                .get(`question/${id}`, {
                    headers: {
                        Authorization: token
                    }
                })
                .then(data => {
                    setLoading(false);

                    setInit({
                        ...data.data.data
                    })

                    setValue({
                        ...data.data.data
                    })
                })
                .catch(error => {
                    setLoading(false)
                    enqueueSnackbar(error, { variant: 'error' })
                    console.log("Error:", error)
                })
        });
    }, [])


    const handleChange = (event) => {
        //destruct the event.target object
        const { name, value, type, checked } = event.target
        //checked and unchecked for check box
        // console.log({ value })
        setValue(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : type === "number" ? parseInt(value) : value
            }
        })
    }

    const handleExampleChange = (event, idx) => {
        // const {name, value} = event.target;

        const example = value.example.slice()
        example[idx][event.target.name] = event.target.value
        setValue(prevFormData => ({
            ...prevFormData,
            example
        }))

    }

    const checkRequired = () => {
        let keys = Object.keys(value);
        const errorKeys = keys.filter((obj) => {
            if (toSkip(obj)) {
                return false
            }
            if (obj === 'score') {
                return !value['score'] ? true : false
            }
            return value[obj]?.length ? false : true;
        })
        // console.log({ keys, value, errorKeys, s: value['score'] })
        if (errorKeys.length !== 0) {
            enqueueSnackbar("Please fill " + errorKeys.join(' , ').toUpperCase() + " fields", { variant: 'error' });
            return false;
        }
        return true;
    }



    const handleSubmit = () => {
        delete value._id
        delete value.isSolved
        delete value.totalSolutions
        delete value.correctSolutions
        delete value.isListed
        delete value.accuracy
        delete value.updatedAt
        delete value.createdAt
        delete value.__v
        delete value.contest
        delete value.id
        delete value.contestId
        delete value.discussionId
        delete value.type
        delete value.allowedLanguages
        checkRequired() && getAuthToken(true).then(token => {
            codeSkillsApi
                .put(`question/${id}`, value,
                    {
                        headers: {
                            Authorization: token
                        }
                    })
                .then(data => {
                    if (!data.data.err) {
                        return enqueueSnackbar('Created', { variant: 'success' })
                    }
                    throw new Error("Something went wrong")
                })
                .catch(error => {
                    enqueueSnackbar(error.message, { variant: 'error' });
                    console.log("Error:", error)
                })
        });
    }

    return (
        loading ? <div className="loader"><CircularProgress /></div> :
            <div className="createBody">
                <Fields value={value} handleChange={handleChange} handleSubmit={handleSubmit} edit={true} handleExampleChange={handleExampleChange} />
            </div>
    )
}
