import moment from "moment";

export const getLanguage = (file) => {
  if (file === "js") {
    return "javascript";
  } else if (file === "c") {
    return "c";
  } else if (file === "cpp") {
    return "cpp";
  } else if (file === "java") {
    return "java";
  } else if (file === "py") {
    return "python";
  }
};

export const supportedLanguages = [
  {
    value: "c",
    label: "C",
  },
  {
    value: "cpp",
    label: "C++ 14",
  },
  {
    value: "java",
    label: "Java",
  },

  {
    value: "python",
    label: "Python 3",
  },
  {
    value: "javascript",
    label: "Nodejs",
  },
];

export const tomiliseconds = (hrs, min, sec) =>
  (hrs * 60 * 60 + min * 60 + sec) * 1000;

export const copyToClipBoard = (text) => {
  return navigator.clipboard.writeText(text);
};

export const getFileExt = (lang) => {
  if (lang === "c") {
    return "c";
  } else if (lang === "cpp") {
    return "cpp";
  } else if (lang === "java") {
    return "java";
  } else if (lang === "python") {
    return "py";
  } else if (lang === "javascript") {
    return "js";
  } else if (lang === "bash") {
    return "sh";
  }
};

// [[CPP, C, PYTHON, JAVA, BASH, NODE]]

export const serverLangType = (lang) => {
  if (lang === "c") {
    return "C";
  } else if (lang === "cpp") {
    return "CPP";
  } else if (lang === "java") {
    return "JAVA";
  } else if (lang === "python") {
    return "PYTHON";
  } else if (lang === "javascript") {
    return "NODE";
  } else if (lang === "bash") {
    return "BASH";
  }
};

export const getCompilationCommand = (lang) => {
  if (serverLangType(lang) === "C") {
    return "gcc Main.c -o a.out";
  } else if (serverLangType(lang) === "CPP") {
    return "g++ Main.cpp -o a.out";
  } else if (serverLangType(lang) === "JAVA") {
    return "java Main.java";
  } else if (serverLangType(lang) === "PYTHON") {
    return "python Main.py";
  } else if (serverLangType(lang) === "NODE") {
    return "node Main.js";
  } else if (serverLangType(lang) === "BASH") {
    return "./Main.sh";
  }
};

export const getDuration = (startDate, endDate) => {
  let start = moment(new Date(startDate)); //todays date
  let end = moment(new Date(endDate)); // another date
  let duration = moment.duration(end.diff(start));
  let days = duration.asHours().toFixed(0);
  // console.log(days)
  return days;
};

export const LANGUAGES = ["CPP", "C", "PYTHON", "JAVA", "NODE", "BASH"];
export const SOLUTION_STATUS = ["Queued", "Evaluating", "Evaluated"];
export const SOLUTION_RESULT = ["AC", "WA", "PA", "NG"];

export const errorMapper = (error) => {
  if (error.type === "GitError") {
    return {
      data: "It is not us but problem with github API, Try after sometime!",
      status: "git",
    };
  }

  if (error.type === "NotFoundError") {
    return {
      status: 404,
      path: "/404",
    };
  }

  if (error.type === "FileError") {
    return {
      data: error.message,
      status: "file",
    };
  }

  if (error.type === "ValidationError") {
    return {
      status: "valid",
      path: "/",
    };
  }

  if (error.type === "Error") {
    return {
      status: 500,
      path: "/500",
    };
  }
  if (error.type === "CustomError") {
    return {
      status: "custom",
      data: error.message,
    };
  }
};

//! validation, UnauthorizedError

export const timeHelper = (one, two) => {
  // @ts-ignore
  let a = moment(one);
  let b = moment(two);
  // const t = moment.utc(b.diff(a))
  return diffYMDHMS(a, b);
  // return `${t.format('HH')} : ${t.format('mm')} : ${t.format('ss')}`
};

function diffYMDHMS(date1, date2) {
  let hours = date1.diff(date2, "hours");
  date2.add(hours, "hours");

  let minutes = date1.diff(date2, "minutes");
  date2.add(minutes, "minutes");

  let seconds = date1.diff(date2, "seconds");

  // console.log(hours + ':' + minutes + ':' + seconds)

  hours = hours < 9 ? "0" + hours : "" + hours;
  minutes = minutes < 9 ? "0" + minutes : "" + minutes;
  seconds = seconds < 9 ? "0" + seconds : "" + seconds;

  return hours + ":" + minutes + ":" + seconds;
}

export const handleFiletoText = async (file, setFile) => {
  fetch(file)
    .then((res) => res.blob())
    .then((blob) => {
      let reader = new FileReader();
      reader.readAsText(blob, "UTF-8");
      reader.onload = function (evt) {
        // console.log(evt.target.result as string)
        setFile(evt.target.result);
      };
      reader.onerror = function (evt) {
        console.log("error reading file");
      };
    });
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const downloadCode = (filename) => {
  let element = document.createElement("a");
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};
