import SSO from '@code-skills-community/sso-react-client';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom';
import { QuestionCard } from '../../components/questionCard/QuestionCard';
import { codeSkillsApi } from '../../utils/axios_config';
export const Question = () => {
    const { getAuthToken } = React.useContext(SSO.Context);
    const { id } = useParams();

    // contains the list of question
    const [questions, setQuestions] = React.useState([]);
    // axios fetch error
    const [loading, setLoading] = React.useState(true)
    // notistack
    const { enqueueSnackbar } = useSnackbar();

    // for infinite scrolling
    const [page, setPage] = React.useState(1);
    const [hasMore, setMore] = React.useState(false);
    const observer = React.useRef();

    const lastElementRef = useCallback(node => {
        console.log("inside callback");
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                console.log("visible");
                setPage(prev => prev + 1);
            }
        })
        if (node) observer.current.observe(node);
    }, [loading, hasMore])
    // for infinite scrolling

    const url = id === 'listedQuestions' ? `question/list?page=${page}` : `contest/${id}/admin/question?page=${page}`;

    React.useEffect(() => {
        getAuthToken(true).then(token => {
            setLoading(true);
            codeSkillsApi
                .get(url, {
                    headers: {
                        Authorization: token
                    }
                })
                .then(res => {
                    setLoading(false);
                    setMore(res.data.data.length > 0 ? true : false)
                    return setQuestions(prev => [...prev, ...res.data.data])
                })
                .catch(error => {
                    setLoading(false);
                    enqueueSnackbar(error, { variant: 'error' });
                    console.log("Error:", error.message)
                })
        });
    }, [page])

    return (
        <div className="homeContainer">
            <div className="homeTitle">Available Questions</div>
            <div className="homeCardsContainer">
                {questions.map((obj, idx) => {
                    return <QuestionCard data={obj} key={idx} />
                })}
            </div>
            <div className="hello" ref={lastElementRef}></div>
            {loading && <div className='loader'> <CircularProgress /> </div>}
        </div>
    )
}
