import SSO from '@code-skills-community/sso-react-client';
import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { MdDelete } from 'react-icons/md';
import ReactMarkdown from 'react-markdown';
import { Link, useParams } from 'react-router-dom';
import { coldarkDark as dark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight';
import rehypeKatex from 'rehype-katex';
import remarkGfm from 'remark-gfm';
import RemarkMathPlugin from 'remark-math';
import { codeSkillsApi } from '../../utils/axios_config';


const initialValue = {
    code: "",
    description: "",
    input: "",
    output: "",
    constraints: "",
    contestId: "",
    type: "",
    isSolved: "",
    totalSolutions: "",
    correctSolutions: "",
    example: "",
    contest: {},
}

export const ViewQuestion = () => {

    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const { getAuthToken } = React.useContext(SSO.Context);
    const [values, setValues] = React.useState(initialValue);
    const [loading, setLoading] = React.useState(true);
    const [testCase, setTestCase] = useState();

    React.useEffect(() => {
        // console.log(id)
        getAuthToken(true).then(token => {
            codeSkillsApi
                .get(`question/${id}`, {
                    headers: {
                        Authorization: token
                    }
                })
                .then(data => {
                    // console.log(data)
                    // setLoading(false);
                    const { name, description, input, output, constraints, contestId, type, isSolved, totalSolutions, correctSolutions, example, contest, difficulty, _id } = data.data.data;
                    setValues({
                        id: _id,
                        name,
                        description,
                        input,
                        output,
                        constraints,
                        contestId,
                        type,
                        isSolved,
                        totalSolutions,
                        correctSolutions,
                        example,
                        contest,
                        difficulty
                    });
                })
                .catch(error => {
                    setLoading(false)
                    enqueueSnackbar(error, { variant: 'error' });
                    console.log("Error:", error)
                })

            codeSkillsApi.get(`testcase/${id}?page=1`, {
                headers: {
                    Authorization: token
                }
            }).then(data => {
                // console.log(data)
                setLoading(false);
                setTestCase(data.data.data)
            })
                .catch(error => {
                    setLoading(false)
                    enqueueSnackbar(error, { variant: 'error' });
                    console.log("Error:", error)
                })

        });
    }, [])


    const deleteTestCase = (id) => {
        getAuthToken(true).then(token => {
            codeSkillsApi
                .delete(`testcase/${id}`, {
                    headers: {
                        Authorization: token
                    }
                })
                .then(data => {
                    // console.log({ data: data, d: data.data })
                    if (!data.data.error) {
                        enqueueSnackbar("Deleted successfully", { variant: "success" })
                        let newTestCases = testCase.filter(t => t._id !== id);
                        setTestCase(newTestCases);
                    }
                })
                .catch(error => {
                    setLoading(false)
                    enqueueSnackbar(error, { variant: 'error' });
                    console.log("Error:", error)
                })



        });
    }


    return (
        loading ? <div className="loader"><CircularProgress /></div> :
            <div className='createBody'>
                <div className="createContainer contestInfo">
                    <div className="subContainers">
                        <div className="nameContainer title">
                            {values.name}
                        </div>
                        <div className="colContainer BasicInfo" style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
                            {values.contest && <div className="nameContainer">
                                <span className='titleContainer'>Contest : &nbsp;</span>
                                <Link to={`/question/view/${values.contest._id}`}>
                                    <span className="valueContainer">{values.contest.name}</span>
                                </Link>
                            </div>}
                            <div className="nameContainer">
                                <span className='titleContainer'>Type : &nbsp;</span>
                                <span className="valueContainer">{values.type}</span>
                            </div>
                            <div className="nameContainer">
                                <span className='titleContainer'>Difficulty : &nbsp;</span>
                                <span className="valueContainer">{values.difficulty}</span>
                            </div>
                            <div className="nameContainer">
                                <span className='titleContainer'>Total Solution : &nbsp;</span>
                                <span className="valueContainer">{values.totalSolutions}</span>
                            </div>
                            <div className="nameContainer">
                                <span className='titleContainer'>Correct Solutions : &nbsp;</span>
                                <span className="valueContainer">{values.correctSolutions}</span>
                            </div>
                            <div className="nameContainer">
                                <span className='titleContainer'>Is Solved : &nbsp;</span>
                                <span className="valueContainer">{values.isSolved ? "YES" : "NO"}</span>
                            </div>
                            <div className="nameContainer lastChild">
                                <span className='titleContainer'>LeaderBoard Visible : &nbsp;</span>
                                <span className="valueContainer">{values.leaderboardVisible ? "True" : "False"}</span>
                            </div>
                        </div>
                        <hr />
                        <div className="colContainer descContainer">
                            <div className="rulesTitle">
                                Description
                            </div>
                            <hr />
                            <div className="valueContainer">
                                <ReactMd text={values.description} />
                            </div>
                        </div>
                    </div>
                    <div className="contestInfoContainer" >
                        <div className="contestRules subContainers">
                            <div className="titleContainer rulesTitle">
                                Constraints :
                            </div>
                            <div className="valueContainer desc">
                                <ReactMd text={values.constraints} />
                            </div>
                        </div>
                        <div className="contestRules subContainers">
                            <div className="titleContainer rulesTitle">
                                Input :
                            </div>
                            <div className="valueContainer desc">
                                <ReactMd text={values.input} />
                            </div>
                        </div>
                        <div className="contestRules subContainers">
                            <div className="titleContainer rulesTitle">
                                output :
                            </div>
                            <div className="valueContainer desc">
                                <ReactMd text={values.output} />
                            </div>
                        </div>
                    </div>
                    <div className="contestPrize subContainers">
                        <div className="titleContainer prizeTitle">
                            Examples :
                        </div>
                        <div className="prizeContainer">
                            {values.example.length !== 0 && values.example.map(eg => {
                                return (
                                    <div className="subPrizes">
                                        <div className="nameContainer mb-4 flex flex-col ">
                                            <div className='titleContainer mr-4 text-left font-semibold w-full'>Input</div>
                                            <textarea disabled className="valueContainer w-full h-[10rem] max-h-96 bg-slate-50 rounded-md" >{eg.input}</textarea>
                                        </div>
                                        <div className="nameContainer flex flex-col">
                                            <div className='titleContainer mr-4 text-left font-semibold w-full'>Output </div>
                                            <textarea disabled className="valueContainer w-full h-[10rem] max-h-96 bg-slate-50 rounded-md">{eg.output}</textarea>
                                        </div>
                                        <div className="prizeDesc nameContainer">
                                            <div className='titleContainer mr-4 font-semibold'>Explanation </div>
                                            <div className="valueContainer">{eg.explanation}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="contestPrize subContainers">
                        <div className="titleContainer prizeTitle">
                            TestCases :
                        </div>
                        <div className="prizeContainer subPrizes">
                            <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
                                <Table size="medium" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Task Name</TableCell>
                                            <TableCell >Score</TableCell>
                                            <TableCell >Time (ms)</TableCell>
                                            <TableCell >Output Number</TableCell>
                                            <TableCell >Required</TableCell>
                                            <TableCell >Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {testCase.map((row) => (
                                            <TableRow key={row._id} >
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="left">{row.score}</TableCell>
                                                <TableCell align="left">
                                                    {row.time}
                                                </TableCell>
                                                <TableCell align="left">{row.outputNo.toString()}</TableCell>
                                                <TableCell align="left">{row.required.toString()}</TableCell>
                                                <TableCell align="center">
                                                    <div
                                                        className="cursor-pointer"
                                                        onClick={() => deleteTestCase(row._id)}

                                                    >
                                                        <MdDelete size={20} />
                                                    </div>
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                        <TableRow
                                            style={{
                                                height: 33,
                                            }}
                                        >
                                            <TableCell colSpan={6}></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>

                </div>
            </div>
    )
}


const ReactMd = ({ text }) => {
    return (
        <div className='proseContainer'>
            <ReactMarkdown
                className={`p-2 prose`}
                children={text}
                remarkPlugins={[remarkGfm, RemarkMathPlugin]}
                rehypePlugins={[rehypeKatex]}
                components={{
                    code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || '')
                        return !inline && match ? (
                            <SyntaxHighlighter
                                children={String(children).replace(/\n$/, '')}
                                style={dark}
                                language={match[1]}
                                PreTag="div"
                                {...props}
                            />
                        ) : (
                            <code className={className} {...props}>
                                {children}
                            </code>
                        )
                    },
                }}
            /></div>
    )
}