import { CircularProgress } from "@mui/material";
import React, { useContext, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsPlusSquareDotted } from "react-icons/bs";
import { codeSkillsApi } from "../../utils/axios_config";
import Editor from "./Editor";
import { useSnackbar } from "notistack";
import SSO from "@code-skills-community/sso-react-client";
import { useNavigate } from "react-router-dom";

const Fields = ({ questionId, initialValues, isUpdate = false }) => {
  // notistack
  const { enqueueSnackbar } = useSnackbar();
  const { getAuthToken } = useContext(SSO.Context);
  const history = useNavigate();

  const [hints, setHints] = useState(initialValues.hints);
  const [description, setDescription] = useState(initialValues.description);
  const [video, setVideo] = useState(initialValues.video);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const resetForm = () => {
    setHints([{ value: "" }]);
    setDescription("👈 Write a description");
    setVideo("");
  };

  const handleDelete = async () => {
    try {
      const token = await getAuthToken();

      const res = await codeSkillsApi({
        method: "DELETE",
        url: `question/${questionId}/editorial`,
        headers: {
          Authorization: token,
        },
      });

      if (res.data) {
        enqueueSnackbar("Successful!", { variant: "success" });
        history("/questions/listedQuestions");
      } else {
        enqueueSnackbar("Something went wrong!", { variant: "error" });
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const hintValues = hints.map((e) => e.value).filter((v) => v);
    const data = {
      hints: hintValues,
      description,
    };

    if (video) data["video"] = video;

    try {
      const token = await getAuthToken();

      const res = await codeSkillsApi({
        method: isUpdate ? "PUT" : "POST",
        url: `question/${questionId}/editorial`,
        data: data,
        headers: {
          Authorization: token,
        },
      });

      if (res.data) {
        enqueueSnackbar("Successful!", { variant: "success" });
        // resetForm();
      } else {
        enqueueSnackbar("Something went wrong!", { variant: "error" });
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar(err.message, { variant: "error" });
    }

    setIsSubmitting(false);
  };

  return (
    <div className="fieldsContainer">
      {hints.map((item, idx) => (
        <div key={idx.toString()} className="codeNameContainer">
          <span className="codeName">Hint_{idx + 1}</span>
          <input
            type="text"
            className="codeNameText"
            placeholder={`Hint ${idx + 1}`}
            value={item.value}
            onChange={(e) =>
              setHints((cur) =>
                Array.from(cur).map((item, i) =>
                  i === idx ? { value: e.target.value } : item
                )
              )
            }
          />

          {idx > 0 && (
            <div
              className="flex items-center grow h-[5vh] mb-[12px] px-2 cursor-pointer"
              onClick={() =>
                setHints((cur) => Array.from(cur).filter((_, i) => i !== idx))
              }
            >
              <AiOutlineCloseCircle fontSize={24} />
            </div>
          )}
        </div>
      ))}

      {hints.length < 3 && (
        <div
          className="flex items-center w-fit"
          onClick={() => setHints((cur) => [...cur, { value: "" }])}
        >
          <BsPlusSquareDotted fontSize={24} />
          <span className="ml-2">Add More</span>
        </div>
      )}

      <div className="py-8">
        <div className="codeNameContainer">
          <span className="codeName !mb-0">Description</span>
        </div>

        <Editor text={description} setText={setDescription} />
      </div>

      <div className="codeNameContainer">
        <span className="codeName">Video</span>
        <input
          type="text"
          className="codeNameText"
          placeholder="Video link"
          value={video}
          onChange={(e) => setVideo(e.target.value)}
        />
      </div>

      <div className="submitBtn mt-6">
        <button
          className="subBtn"
          disabled={isSubmitting}
          style={isSubmitting ? { backgroundColor: "rgba(0, 0, 0, 0.3)" } : {}}
          onClick={handleSubmit}
        >
          <span className="px-2">Submit</span>
          {isSubmitting && (
            <CircularProgress style={{ width: "0.9em", height: "0.9em" }} />
          )}
        </button>

        {isUpdate && (
          <button className="subBtn !bg-red-600 ml-4" onClick={handleDelete}>
            Delete
          </button>
        )}
      </div>
    </div>
  );
};

export default Fields;
