import React, { useEffect, useState } from 'react'
import { FullScreen } from 'react-full-screen'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/ace'
import 'ace-builds/src-noconflict/mode-java'
import 'ace-builds/src-noconflict/mode-javascript'
import 'ace-builds/src-noconflict/mode-c_cpp'
import 'ace-builds/src-noconflict/mode-python'
import 'ace-builds/src-noconflict/mode-sh'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/theme-nord_dark'
import 'ace-builds/src-noconflict/theme-chaos'
import 'ace-builds/src-noconflict/theme-ambiance'
import 'ace-builds/src-noconflict/theme-chrome'
import 'ace-builds/src-noconflict/snippets/c_cpp'
import 'ace-builds/src-noconflict/snippets/python'
import 'ace-builds/src-noconflict/snippets/java'
import 'ace-builds/src-noconflict/snippets/javascript'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/snippets/sh'

const ACEeditor = ({
    editorContent,
    handleEditorChange,
    language,
    defaultValue,
    handleFullScreen,
    theme,
    fontSize,
    tabSize,
    readOnly = false,
}) => {
    return (
        <FullScreen handle={handleFullScreen} className="h-full">
            <AceEditor
                height="100%"
                mode={
                    language === 'c' || language === 'cpp'
                        ? 'c_cpp'
                        : language === 'bash'
                            ? 'sh'
                            : language
                }
                value={editorContent}
                onChange={handleEditorChange}
                theme={theme}
                highlightActiveLine={true}
                readOnly={readOnly}
                showGutter={true}
                width="100%"
                fontSize={fontSize}
                setOptions={{
                    fontFamily: 'Monospace',
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableEmmet: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    showFoldWidgets: true,
                    tabSize: tabSize,
                }}
            />
        </FullScreen>
    )
}

export default ACEeditor

// ["CPP", "C", "PYTHON", "JAVA", "BASH", "NODE"]
